import React, { useState, useEffect } from 'react';
import Page from './Page';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Card from 'ui/Card';
import StepContent from '@material-ui/core/StepContent';
import VenueDetailsStep from './VenueDetailsStep/VenueDetailsStep';
import AddStationStep from './AddStationStep/AddStationStep';
import VenueContactStep from './VenueContactStep/VenueContactStep';
import { makeStyles } from '@material-ui/core/styles';
import RequestGeolocation from './RequestGeolocation';
import { useDispatch } from 'react-redux';
import { createStationInstallation } from 'app/agents';
import { useNotifications } from 'components/Notifications/useNotifications';
import { getErrorMessage } from 'shared/utils/errors';
import CreationSuccess from './CreationSuccess/CreationSuccess';
import { useTranslation } from 'react-i18next';
import contract from './CreationSuccess/contract_de.jpg';
import { useShallowSelector } from 'shared/hooks/useShallowSelector';
import { getNewStationInstallationPageCreateState } from 'app/selectors';

const preloadContractImage = () => {
  new Image().src = contract;
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    maxWidth: theme.breakpoints.width('sm'),
    width: '100%'
  },

  stepper: {
    backgroundColor: 'transparent',
    width: '100%'
  }
}));

const NewInstallation = () => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const dispatch = useDispatch();
  const [venueDetailsState, setVenueDetailsState] = useState(null);
  const [addedStations, setAddedStations] = useState([]);
  const [contactDetailsState, setContactDetailsState] = useState(null);
  const [isCreated, setIsCreated] = useState(false);
  const { showErrorNotification } = useNotifications();
  const { t } = useTranslation();
  const { data: createdStationInstallation } = useShallowSelector(
    getNewStationInstallationPageCreateState
  );

  const goToPreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const goToNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const completeVenueDetails = venue => {
    setVenueDetailsState(venue);
    goToNextStep();
  };

  const completeAddStation = stations => {
    setAddedStations(stations);
    goToNextStep();
  };

  const completeContactDetails = async contact => {
    setContactDetailsState(contact);

    await submitInstallation(venueDetailsState, addedStations, contact);
  };

  const submitInstallation = async (venueDetails, stations, venueContact) => {
    try {
      const { address, ...venue } = venueDetails;

      await dispatch(
        createStationInstallation({ venue, stations, venueContact })
      );
      setIsCreated(true);
    } catch (error) {
      showErrorNotification(
        `Was not able to process your application - ${getErrorMessage(error)}`
      );
    }
  };

  useEffect(preloadContractImage, []);

  return (
    <Page>
      <Card className={classes.wrapper}>
        {isCreated && createStationInstallation && (
          <CreationSuccess
            installationId={createdStationInstallation.installationId}
          />
        )}

        {!isCreated && (
          <RequestGeolocation>
            {geolocation => (
              <Stepper
                orientation="vertical"
                className={classes.stepper}
                activeStep={currentStep}
              >
                <Step>
                  <StepLabel>{t('addStation.step1.title')}</StepLabel>
                  <StepContent>
                    <VenueDetailsStep
                      onNext={completeVenueDetails}
                      initialValues={venueDetailsState}
                      geolocation={geolocation}
                    />
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>{t('addStation.step2.title')}</StepLabel>
                  <StepContent>
                    <AddStationStep
                      onBack={goToPreviousStep}
                      onNext={completeAddStation}
                      initialValues={addedStations}
                      geolocation={geolocation}
                    />
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>{t('addStation.step3.title')}</StepLabel>
                  <StepContent>
                    <VenueContactStep
                      onBack={goToPreviousStep}
                      onNext={completeContactDetails}
                      initialValues={contactDetailsState}
                    />
                  </StepContent>
                </Step>
              </Stepper>
            )}
          </RequestGeolocation>
        )}
      </Card>
    </Page>
  );
};

export default NewInstallation;
