import theme from '../default';
import { rem } from 'polished';

export const MuiInputLabel = {
  root: {
    fontFamily: theme.fonts.sans,
    color: theme.colors.grey[5],

    '&$focused': {
      color: theme.colors.grey[5]
    },

    '&$error': {
      color: theme.colors.red[4]
    },

    '&$error& $asterisk': {
      color: theme.colors.red[4]
    },

    '&$disabled': {
      color: theme.colors.grey[2]
    }
  },

  outlined: {
    transform: `translate(${rem(12)}, ${rem(17)}) scale(1)`
  },

  marginDense: {
    fontSize: theme.fontSizes[1],

    '&$outlined&$focused': {
      fontSize: theme.fontSizes[2],
      backgroundColor: 'white',
      paddingRight: theme.space[1]
    }
  }
};

export const MuiOutlinedInput = {
  notchedOutline: {
    borderColor: theme.colors.grey[2],
    borderWidth: theme.borderWidths[1]
  },

  input: {
    padding: `${theme.space[4]} ${theme.space[3]}`
  },

  inputMultiline: {
    lineHeight: theme.lineHeights.normal
  },

  root: {
    fontFamily: theme.fonts.sans,
    color: theme.colors.text.secondary,

    '&$disabled $input': {
      color: theme.colors.grey[2],
      cursor: 'not-allowed'
    },

    '&:hover $notchedOutline': {
      borderColor: theme.colors.grey[5]
    },

    '@media (hover: none)': {
      '&:hover $notchedOutline': {
        borderColor: theme.colors.grey[5]
      }
    },

    '&$focused $notchedOutline': {
      borderColor: theme.colors.grey[5]
    },

    '&$error $notchedOutline': {
      borderColor: theme.colors.red[4]
    },

    '&$disabled $notchedOutline': {
      borderColor: theme.colors.grey[0]
    }
  }
};

export const MuiFormHelperText = {
  root: {
    fontSize: theme.fontSizes[0],
    letterSpacing: theme.letterSpacings.normal,
    lineHeight: theme.lineHeights.normal,
    fontFamily: theme.fonts.sans,
    fontStyle: 'italic',
    color: theme.colors.text.secondary,

    '&$contained': {
      marginTop: theme.space[2],
      margin: 0
    },

    '&$error': {
      color: theme.colors.red[5]
    }
  }
};

export const MuiSelect = {
  select: {
    '&:focus': {
      backgroundColor: theme.colors.grey[0]
    }
  }
};

export const MuiFormControlLabel = {
  label: {
    fontFamily: theme.fonts.sans,
    fontSize: theme.fontSizes[2],
    color: theme.colors.text.secondary
  }
};

export const MuiCheckbox = {
  root: {
    color: theme.colors.grey[2]
  },

  colorPrimary: {
    '&$checked': {
      color: theme.colors.grey[6]
    }
  }
};
