import { useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

export const useRouterHelpers = params => {
  const { pathname: currentPath } = useLocation();

  const isActiveRoute = useCallback(
    path => {
      return Boolean(
        matchPath(currentPath, {
          path,
          exact: true
        })
      );
    },
    [currentPath]
  );

  return {
    isActiveRoute
  };
};
