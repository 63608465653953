import React from 'react';
import ErrorTitle from './ErrorTitle';
import AccessDeniedImage from './images/access-denied.svg';
import ErrorImage from './ErrorImage';

const AccessDenied = () => {
  return (
    <>
      <ErrorImage src={AccessDeniedImage} />
      <ErrorTitle>
        You don't have access to this page{' '}
        <span role="img" aria-label="lock with key emoji">
          🔐
        </span>
      </ErrorTitle>
    </>
  );
};

export default AccessDenied;
