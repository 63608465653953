import {
  getStationInstallation as getStationInstallationApi,
  completeStationInstallationReview as completeStationInstallationReviewApi,
  updateStationInstallationContactDetails as updateStationInstallationContactDetailsApi
} from '../api/stationsInstallations';
import {
  createAsyncAction,
  asyncInitialState,
  createAsyncReducerHandlers
} from './shared/async';
import { createReducer, combineReducers } from '@reduxjs/toolkit';
import { getReviewStationInstallations } from './review';

export const getStationInstallation = createAsyncAction(
  'get-station-installation',
  getStationInstallationApi
);
export const completeStationInstallationReview = createAsyncAction(
  'complete-station-installation-review',
  async (stationInstallationId, dispatch) => {
    await completeStationInstallationReviewApi(stationInstallationId);
    await dispatch(getReviewStationInstallations());
  }
);

export const updateStationInstallationContactDetails = createAsyncAction(
  'update-station-installation-contact-details',
  updateStationInstallationContactDetailsApi
);

const details = createReducer(asyncInitialState, {
  ...createAsyncReducerHandlers(getStationInstallation),

  [completeStationInstallationReview.success]: state => {
    if (state.data) {
      state.data.isReviewed = true;
    }
  },

  [updateStationInstallationContactDetails.success]: (state, action) => {
    if (state.data) {
      state.data.venueContact = action.payload;
    }
  }
});

const completeReview = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(completeStationInstallationReview)
);

const updateContactDetails = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(updateStationInstallationContactDetails)
);

export const stationInstallation = combineReducers({
  details,
  completeReview,
  updateContactDetails
});
