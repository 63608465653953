import { get, post } from './shared';

export const getStationLocationUploadUrl = async () => {
  const response = await get(
    'agents/installations/station-location/upload-url'
  );

  return response.data;
};

export const createStationInstallation = async installation => {
  const response = await post('agents/installations', {
    data: installation
  });

  return response.data;
};

export const signContractLater = async installationId => {
  await post(`agents/installations/${installationId}/sign-later`);
};

export const signContractNow = async installationId => {
  await post(`agents/installations/${installationId}/sign-now`);
};
