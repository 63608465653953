import {
  createAsyncAction,
  asyncInitialState,
  createAsyncReducerHandlers
} from './shared/async';
import { createReducer } from '@reduxjs/toolkit';
import {
  searchAll as searchAllApi,
  searchStations as searchStationsApi,
  searchVenues as searchVenuesApi
} from '../api/search';

export const searchAll = createAsyncAction('application-search', searchAllApi);
export const searchForStation = createAsyncAction(
  'search-stations',
  searchStationsApi
);
export const searchForVenues = createAsyncAction(
  'search-venues',
  searchVenuesApi
);

export const search = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(searchAll)
);
