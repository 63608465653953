import React, { useState, useRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { logout } from '../../app/authentication';
import SearchBar from './SearchBar';
import { rem } from 'polished';
import { useNavigation } from '../../shared/hooks/useNavigation';
import Logo from 'components/Logo';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },

  actions: {
    width: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    [theme.breakpoints.down('sm')]: {
      width: '10%'
    }
  },

  search: {
    width: '60%',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '80%'
    }
  },

  searchInner: {
    width: '100%',
    maxWidth: rem(500)
  },

  menuButton: {
    width: '10%',

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },

  logo: {
    width: '20%',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

const AppHeader = props => {
  const { onNavigationOpen } = props;
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const menuAnchor = useRef(null);
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    goToStationDetails,
    goToVenueDetails,
    goToPromoCodeDetails,
    goToUser
  } = useNavigation();

  const openMenu = () => {
    setIsProfileMenuOpen(true);
  };

  const closeMenu = () => {
    setIsProfileMenuOpen(false);
  };

  const onLogoutClicked = () => {
    closeMenu();
    dispatch(logout());
  };

  const handleSearchResult = result => {
    if (result.type === 'station') {
      goToStationDetails(result.id);
    }

    if (result.type === 'venue') {
      goToVenueDetails(result.id);
    }

    if (result.type === 'promoCode') {
      goToPromoCodeDetails(result.id);
    }

    if (result.type === 'user') {
      goToUser(result.id);
    }
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <div className={classes.menuButton}>
          <IconButton color="inherit" onClick={onNavigationOpen} edge="start">
            <MenuIcon />
          </IconButton>
        </div>
        <div className={classes.logo}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className={classes.search}>
          <div className={classes.searchInner}>
            <SearchBar onSelectedResult={handleSearchResult} />
          </div>
        </div>
        <div className={classes.actions}>
          <IconButton
            color="inherit"
            ref={menuAnchor}
            onClick={openMenu}
            edge="end"
          >
            <AccountCircleOutlinedIcon />
          </IconButton>
          <Menu
            open={isProfileMenuOpen}
            anchorEl={menuAnchor.current}
            onClose={closeMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <MenuItem onClick={onLogoutClicked}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
