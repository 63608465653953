import { getTookanReport as getTookanReportApi } from '../api/reports';
import {
  createAsyncAction,
  asyncInitialState,
  createAsyncReducerHandlers
} from './shared/async';
import { createReducer, combineReducers } from '@reduxjs/toolkit';
import fileSaver from 'file-saver';

export const getTookanReport = createAsyncAction(
  'get-tookan-report',
  async () => {
    const report = await getTookanReportApi();
    const reportBlob = new Blob([report], { type: 'text/csv' });

    fileSaver.saveAs(reportBlob, 'tookan-report.csv');
  }
);

const downloadTookanReport = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(getTookanReport)
);

export const reports = combineReducers({
  downloadTookanReport
});
