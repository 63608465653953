import {
  createAsyncAction,
  asyncInitialState,
  createAsyncReducerHandlers
} from './shared/async';
import { createReducer, combineReducers } from '@reduxjs/toolkit';
import {
  findStationCountsInLocation as findStationCountsInLocationApi,
  findVenuesWithStationsInLocation as findVenuesWithStationsInLocationApi
} from '../api/maps';

export const findStationCountsInLocation = createAsyncAction(
  'find-station-counts-in-location',
  findStationCountsInLocationApi
);

export const findVenuesWithStationsInLocation = createAsyncAction(
  'find-venues-with-stations-in-location',
  findVenuesWithStationsInLocationApi
);

const stationCounts = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(findStationCountsInLocation)
);

const venuesWithStations = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(findVenuesWithStationsInLocation)
);

export const dashboard = combineReducers({
  stationCounts,
  venuesWithStations
});
