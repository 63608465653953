import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Form from '../../../../../components/Form/Form';
import { Field, Form as FormikForm } from 'formik';
import AddressField from '../../../../../ui/AddressSearch/AddressField';
import { TextField } from 'formik-material-ui';
import {
  getGooglePlaceIdFromAddress,
  getPlaceDetailsFromPlaceId
} from 'shared/utils/googlePlaces';
import { CircularProgress } from '@material-ui/core';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from 'ui/Button';

const buildFormSchema = t =>
  Yup.object().shape({
    address: Yup.string()
      .trim()
      .required(t('addStation.step1.form.address.errors.required')),

    name: Yup.string()
      .trim()
      .required(t('addStation.step1.form.name.errors.required')),

    description: Yup.string()
      .trim()
      .required(t('addStation.step1.form.description.errors.required'))
  });

const defaultValues = {
  name: '',
  address: '',
  description: ''
};

const buildGoogleLocation = (lat, long) =>
  new window.google.maps.LatLng(lat, long);

const VenueDetailsStep = props => {
  const { onNext, initialValues, geolocation } = props;
  const [isRetrievingName, setIsRetrievingName] = useState(false);
  const isValidForm = Boolean(initialValues);
  const { t } = useTranslation();

  const onAddressSelected = setFieldValue => async address => {
    setIsRetrievingName(true);
    const googlePlaceId = await getGooglePlaceIdFromAddress(address);
    const addressDetails = await getPlaceDetailsFromPlaceId(googlePlaceId);

    setFieldValue('name', addressDetails.name);
    setIsRetrievingName(false);
  };

  const onSubmit = async (values, actions) => {
    const googlePlaceId = await getGooglePlaceIdFromAddress(values.address);

    if (!googlePlaceId) {
      actions.setFieldError(
        'address',
        t('addStation.step1.form.address.errors.invalid')
      );
      return;
    }

    onNext({ ...values, googlePlaceId });
  };

  return (
    <Form
      initialValues={initialValues || defaultValues}
      onSubmit={onSubmit}
      validationSchema={buildFormSchema(t)}
      isInitialValid={isValidForm}
    >
      {({ setFieldValue, isValid, isSubmitting }) => (
        <FormikForm noValidate>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Field
                name="address"
                id="address"
                label={t('addStation.step1.form.address.label')}
                type="text"
                component={AddressField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoFocus
                onAddressSelected={onAddressSelected(setFieldValue)}
                searchOptions={{
                  location: buildGoogleLocation(
                    geolocation.latitude,
                    geolocation.longitude
                  ),
                  radius: 2000
                }}
              />
            </Grid>
            <Grid item>
              <Field
                name="name"
                id="name"
                label={t('addStation.step1.form.name.label')}
                type="text"
                component={TextField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                disabled={isRetrievingName}
                InputProps={{
                  endAdornment: isRetrievingName && (
                    <CircularProgress color="inherit" size={18} />
                  )
                }}
              />
            </Grid>
            <Grid item>
              <Field
                name="description"
                id="description"
                label={t('addStation.step1.form.description.label')}
                type="text"
                component={TextField}
                variant="outlined"
                margin="normal"
                multiline
                rows={3}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={4}>
            <Button disabled={!isValid} loading={isSubmitting} type="submit">
              {t('common.next')}
            </Button>
          </Box>
        </FormikForm>
      )}
    </Form>
  );
};

export default VenueDetailsStep;
