import { useLoadScript } from '@react-google-maps/api';

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const googleLibraries = ['places'];

export const useLoadPlacesScript = () => {
  return useLoadScript({
    googleMapsApiKey,
    libraries: googleLibraries
  });
};
