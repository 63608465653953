import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchAll } from '../../app/search';
import SearchInputWithStationScanner from '../SearchInput/SearchInputWithStationScanner';
import { selectSearchProps } from './selectors';

const SearchBar = props => {
  const { onSelectedResult } = props;
  const dispatch = useDispatch();
  const searchState = useSelector(selectSearchProps);

  const onSearch = searchValue => {
    dispatch(searchAll(searchValue));
  };

  return (
    <SearchInputWithStationScanner
      onSelected={onSelectedResult}
      onSearch={onSearch}
      searchState={searchState}
      variant="dark"
      placeholder="Search for venues, stations, users..."
    />
  );
};

export default SearchBar;
