import { get, post, put } from './shared';

export const getStationInstallation = async installationId => {
  const response = await get(`stations-installations/${installationId}`);

  return response.data;
};

export const completeStationInstallationReview = async installationId => {
  await post(`stations-installations/${installationId}/complete-review`);
};

export const updateStationInstallationContactDetails = async (
  installationId,
  contactDetails
) => {
  const response = await put(
    `stations-installations/${installationId}/venue-contact`,
    {
      data: contactDetails
    }
  );

  return response.data;
};
