import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'moment/locale/en-gb';
import Root from './Root';
import { ThemeProvider } from 'styled-components/macro';
import { StylesProvider, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import { store } from './store';
import { BrowserRouter, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Notifications from './components/Notifications/Notifications';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as Sentry from '@sentry/browser';
import 'moment-duration-format';
import './i18n';
import theme from './ui/theme/default';
import muiTheme from './ui/theme/mui';
import GlobalStyle from './GlobalStyle';
import { SnackbarProvider } from 'notistack';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE
});

const locale = window.navigator.userLanguage || window.navigator.language;

moment.locale(locale);

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <StylesProvider injectFirst>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider>
              <Notifications>
                <BrowserRouter>
                  <Route component={Root} />
                </BrowserRouter>
              </Notifications>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
