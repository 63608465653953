import React, { useState, useRef, useEffect } from 'react';
import Downshift from 'downshift';
import Popper from '@material-ui/core/Popper';
import Card from 'ui/Card';
import Input from './Input';
import SearchResults from './SearchResults';
import { prop } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import useDebounce from 'react-use/lib/useDebounce';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    width: '100%'
  },

  dropdown: {
    zIndex: theme.zIndex.tooltip,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const SearchInput = props => {
  const {
    searchState,
    onSelected,
    onSearch,
    variant,
    actions,
    ...inputProps
  } = props;
  const [searchValue, setSearchValue] = useState('');
  const classes = useStyles();
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const onInputValueChange = value => {
    setSearchValue(value || '');
  };

  const onChange = result => {
    if (!result) {
      return;
    }

    onSelected(result);
  };

  useDebounce(
    () => {
      onSearch(searchValue);
    },
    500,
    [searchValue]
  );

  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.update();
    }
  }, [searchState]);

  return (
    <>
      <Downshift
        onChange={onChange}
        onInputValueChange={onInputValueChange}
        itemToString={prop('title')}
        defaultHighlightedIndex={0}
      >
        {({
          isOpen,
          getMenuProps,
          getInputProps,
          clearSelection,
          getItemProps,
          highlightedIndex
        }) => (
          <div className={classes.wrapper}>
            <div ref={inputRef}>
              <Input
                variant={variant}
                actions={actions}
                {...inputProps}
                {...getInputProps({
                  onChange: clearSelection
                })}
              />
            </div>

            <Popper
              popperRef={dropdownRef}
              open={isOpen}
              anchorEl={inputRef.current}
              placement="bottom-start"
              className={classes.dropdown}
              style={{
                width: inputRef.current && inputRef.current.clientWidth
              }}
            >
              <div {...getMenuProps({}, { suppressRefError: true })}>
                <Card boxShadow="lg" data-test-id="search-results">
                  <SearchResults
                    {...searchState}
                    getItemProps={getItemProps}
                    highlightedIndex={highlightedIndex}
                  />
                </Card>
              </div>
            </Popper>
          </div>
        )}
      </Downshift>
    </>
  );
};

export default SearchInput;
