import {
  asyncInitialState,
  createAsyncAction,
  createAsyncReducerHandlers
} from './shared/async';
import {
  getCampaigns as getCampaignsApi,
  createCampaign as createCampaignApi,
  getAssetUploadUrl as getAssetUploadUrlApi,
  getCampaign as getCampaignApi,
  updateCampaign as updateCampaignApi,
  deleteCampaign as deleteCampaignApi,
  associateCampaign as associateCampaignApi,
  disassociateCampaign as disassociateCampaignApi
} from '../api/campaigns';
import { createReducer, combineReducers } from '@reduxjs/toolkit';
import { uploadFileToS3Url } from '../api/uploader';
import omit from 'lodash/omit';

const buildNewAsset = async asset => {
  const { image, ...newAsset } = asset;
  const { url, key } = await getAssetUploadUrlApi();

  await uploadFileToS3Url(image, url);

  return {
    ...newAsset,
    key
  };
};

const hasAlreadyBeenUploaded = asset => Boolean(asset.url);

const findExistingAssets = assets => assets.filter(hasAlreadyBeenUploaded);
const findNewAssets = assets =>
  assets.filter(asset => !hasAlreadyBeenUploaded(asset));

const prepareExistingAssets = assets => assets.map(asset => omit(asset, 'url'));

const buildNewAssetsWithUploadedImages = assets => {
  return Promise.all(assets.map(buildNewAsset));
};

const buildUpdatedAssets = async assets => {
  const existingAssets = findExistingAssets(assets);
  const newAssets = findNewAssets(assets);

  const uploadedAssets = await buildNewAssetsWithUploadedImages(newAssets);
  const preparedExistingAssets = prepareExistingAssets(existingAssets);

  return [...preparedExistingAssets, ...uploadedAssets];
};

export const getCampaigns = createAsyncAction('get-campaigns', getCampaignsApi);
export const getCampaign = createAsyncAction('get-campaign', getCampaignApi);

export const createCampaign = createAsyncAction(
  'create-campaign',
  async campaign => {
    const { assets, ...newCampaign } = campaign;
    const addedAssets = await buildNewAssetsWithUploadedImages(assets);

    await createCampaignApi({
      ...newCampaign,
      assets: addedAssets
    });
  }
);

export const updateCampaign = createAsyncAction(
  'update-campaign',
  async (campaignName, updatedCampaign) => {
    const { assets, ...updates } = updatedCampaign;

    const updatedAssets = await buildUpdatedAssets(assets);

    await updateCampaignApi(campaignName, {
      ...updates,
      assets: updatedAssets
    });
  }
);

export const deleteCampaign = createAsyncAction(
  'delete-campaign',
  async campaignName => {
    await deleteCampaignApi(campaignName);
  }
);

export const associateCampaign = createAsyncAction(
  'associate-campaign',
  async campaignName => {
    await associateCampaignApi(campaignName);
  }
);
export const disassociateCampaign = createAsyncAction(
  'associate-campaign',
  async campaignName => {
    await disassociateCampaignApi(campaignName);
  }
);

const details = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(getCampaign)
);

export const campaigns = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(getCampaigns)
);

export const editCampaign = combineReducers({
  details
});
