import React, { createContext } from 'react';
import { useNotificationsManager } from './useNotificationsManager';

export const NotificationsContext = createContext({});

const Notifications = props => {
  const {
    showErrorNotification,
    showSuccessNotification
  } = useNotificationsManager();

  return (
    <NotificationsContext.Provider
      value={{ showErrorNotification, showSuccessNotification }}
    >
      {props.children}
    </NotificationsContext.Provider>
  );
};

export default Notifications;
