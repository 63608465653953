import React from 'react';
import ErrorImage from './ErrorImage';
import AppUpgradeAvailableImage from './images/app-upgrade-available.svg';
import ErrorTitle from './ErrorTitle';
import Button from 'ui/Button';
import Box from 'ui/Box';

const AppUpgradeAvailable = () => {
  const reload = () => {
    window.location.reload();
  };

  return (
    <>
      <ErrorImage src={AppUpgradeAvailableImage} />
      <ErrorTitle>
        A new version of the application is available. Please reload your
        browser.
      </ErrorTitle>
      <Box mt={4}>
        <Button onClick={reload}>Reload</Button>
      </Box>
    </>
  );
};

export default AppUpgradeAvailable;
