import React from 'react';
import Form from 'components/Form/Form';
import { Field, Form as FormikForm } from 'formik';
import { TextField } from 'formik-material-ui';
import { CardBody, CardActions } from 'ui/Card';
import Button from 'ui/Button';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email()
    .required()
});

const EmailForm = props => {
  const { onComplete } = props;

  return (
    <Form
      initialValues={{ email: '' }}
      validationSchema={ValidationSchema}
      onSubmit={onComplete}
    >
      {({ isValid, isSubmitting }) => (
        <FormikForm noValidate>
          <CardBody>
            <Field
              name="email"
              id="email"
              type="text"
              label="Email"
              component={TextField}
              variant="outlined"
              margin="normal"
              fullWidth
              autoFocus
              required
            />
          </CardBody>
          <CardActions>
            <Button
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
              fullWidth
            >
              Send reset link
            </Button>
          </CardActions>
        </FormikForm>
      )}
    </Form>
  );
};

export default EmailForm;
