import { get, post, put, del } from './shared';

export const getCampaigns = async () => {
  const response = await get('campaigns');

  return response.data;
};

export const createCampaign = async campaign => {
  await post('campaigns', {
    data: campaign
  });
};

export const getAssetUploadUrl = async () => {
  const response = await get('campaigns/assets/upload-url');

  return response.data;
};

export const getCampaign = async campaignName => {
  const response = await get(`campaigns/${campaignName}`);

  return response.data;
};

export const updateCampaign = async (campaignName, updatedCampaign) => {
  await put(`campaigns/${campaignName}`, {
    data: updatedCampaign
  });
};

export const deleteCampaign = async campaignName => {
  await del(`campaigns/${campaignName}`);
};

export const associateCampaign = async campaignName => {
  await put(`campaigns/${campaignName}/associate`);
};

export const disassociateCampaign = async campaignName => {
  await put(`campaigns/${campaignName}/disassociate`);
};
