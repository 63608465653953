import { PureComponent } from 'react';
import * as Sentry from '@sentry/browser';
import { isLazyLoaderError } from 'shared/utils/errors';

const shouldLogError = error => !isLazyLoaderError(error);

class ErrorHandler extends PureComponent {
  state = {
    hasError: false,
    error: null
  };

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error
    };
  }

  componentDidCatch(error, info) {
    if (shouldLogError(error)) {
      Sentry.withScope(scope => {
        scope.setExtras(info);
        Sentry.captureException(error);
      });
    }
  }

  render() {
    return this.props.children(this.state);
  }
}

export default ErrorHandler;
