import React, { useState } from 'react';
import AppHeader from '../AppHeader/AppHeader';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Hidden from '@material-ui/core/Hidden';
import { rem } from 'polished';
import Navigation from '../Navigation/Navigation';

export const navigationWidth = rem(260);
export const mobileNavigationWidth = '60%';

const useStyles = makeStyles(theme => ({
  page: {
    minHeight: '100vh',
    display: 'flex'
  },

  content: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: theme.spacing(6),
    maxWidth: rem(1200),
    width: '100%',
    margin: '0 auto',
    minWidth: 0
  },

  drawer: {
    width: navigationWidth,
    flexShrink: 0,

    [theme.breakpoints.down('sm')]: {
      width: mobileNavigationWidth
    }
  },

  drawerPaper: {
    width: navigationWidth,

    [theme.breakpoints.down('sm')]: {
      width: mobileNavigationWidth
    }
  },

  main: {
    flex: 1
  },

  toolbar: theme.mixins.toolbar
}));

const Layout = props => {
  const classes = useStyles();
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  const openNavigation = () => {
    setIsNavigationOpen(true);
  };

  const closeNavigation = () => {
    setIsNavigationOpen(false);
  };

  return (
    <div className={classes.page}>
      <AppHeader onNavigationOpen={openNavigation} />

      <Hidden smDown>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{ paper: classes.drawerPaper }}
        >
          <div className={classes.toolbar} />
          <Navigation />
        </Drawer>
      </Hidden>

      <Hidden mdUp>
        <SwipeableDrawer
          variant="temporary"
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          open={isNavigationOpen}
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          onClose={closeNavigation}
          onOpen={openNavigation}
        >
          <div role="presentation" onClick={closeNavigation}>
            <Navigation />
          </div>
        </SwipeableDrawer>
      </Hidden>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
};

export default Layout;
