import { get, del, post } from './shared';

export const getUser = async userId => {
  const response = await get(`users/${userId}`);

  return response.data;
};

export const getUserBatteriesLendings = async userId => {
  const response = await get(`users/${userId}/batteries-lendings`);

  return response.data;
};

export const getUserPromoCodes = async userId => {
  const response = await get(`users/${userId}/promo-codes`);

  return response.data;
};

export const deleteUser = async userId => {
  await del(`users/${userId}`);
};

export const fixUserSwap = async userId => {
  await post(`users/${userId}/fix-swap`);
};
