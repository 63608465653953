import {
  createAsyncAction,
  asyncInitialState,
  createAsyncReducerHandlers
} from './shared/async';
import {
  getChains as getChainsApi,
  createChain as createChainApi
} from '../api/chains';
import { createReducer } from '@reduxjs/toolkit';

export const getChains = createAsyncAction('get-chains', getChainsApi);
export const createChain = createAsyncAction('create-chain', createChainApi);

export const chains = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(getChains)
);
