import {
  getWithoutAuthorisation,
  postWithoutAuthorisation,
  post
} from './shared';

export const getInvite = async inviteId => {
  const response = await getWithoutAuthorisation(`invites/${inviteId}`);

  return response.data;
};

export const completeInvite = async (inviteId, password) => {
  await postWithoutAuthorisation(`invites/${inviteId}/complete`, {
    data: {
      password
    }
  });
};

export const sendInvite = async invite => {
  await post('invites', {
    data: invite
  });
};
