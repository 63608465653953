import React from 'react';
import Form from 'components/Form/Form';
import { Field, Form as FormikForm } from 'formik';
import { TextField } from 'formik-material-ui';
import { CardBody, CardActions } from 'ui/Card';
import Button from 'ui/Button';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(8)
    .matches(/[a-z]/, 'password must have at least one lowercase letter')
    .matches(/[A-Z]/, 'password must have at least one uppercase letter')
    .matches(/\d/, 'password must have at least one digit')
    .matches(/[^a-zA-Z\d]/, 'password must have at least one symbol')
    .required()
});

const FinishForm = props => {
  const { onComplete } = props;

  return (
    <Form
      initialValues={{ password: '' }}
      validationSchema={ValidationSchema}
      onSubmit={onComplete}
    >
      {({ isSubmitting }) => (
        <FormikForm noValidate>
          <CardBody>
            <Field
              name="password"
              id="password"
              type="password"
              label="Password"
              component={TextField}
              variant="outlined"
              margin="normal"
              fullWidth
              autoFocus
              required
            />
          </CardBody>
          <CardActions>
            <Button type="submit" loading={isSubmitting} fullWidth>
              Reset password
            </Button>
          </CardActions>
        </FormikForm>
      )}
    </Form>
  );
};

export default FinishForm;
