import { createAction } from '@reduxjs/toolkit';

export const asyncInitialState = {
  loading: false,
  data: null,
  request: null,
  error: null
};

const emptyPreparator = () => ({});

const createAsyncActionPreparator = (regularPreparator = emptyPreparator) => (
  payload,
  ...args
) => ({
  payload,
  ...regularPreparator(...args)
});

export const createAsyncAction = (name, thunk, prepareAction) => {
  const request = createAction(
    `${name}:request`,
    createAsyncActionPreparator(prepareAction)
  );

  const success = createAction(
    `${name}:success`,
    createAsyncActionPreparator(prepareAction)
  );

  const error = createAction(
    `${name}:error`,
    createAsyncActionPreparator(prepareAction)
  );

  const actionCreator = (...args) => async (dispatch, getState) => {
    try {
      dispatch(request(args, ...args));
      const data = await thunk(...args, dispatch, getState);
      dispatch(success(data, ...args));

      return data;
    } catch (err) {
      dispatch(error(err, ...args));
      throw err;
    }
  };

  actionCreator.request = request;
  actionCreator.success = success;
  actionCreator.error = error;

  return actionCreator;
};

export const createAsyncReducerHandlers = actionCreator => ({
  [actionCreator.request]: (state, action) => {
    state.loading = true;
    state.request = action.payload;
    state.error = null;
    state.data = null;
  },
  [actionCreator.success]: (state, action) => {
    state.loading = false;
    state.data = action.payload;
  },
  [actionCreator.error]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  }
});
