import { getBattery as getBatteryApi } from '../api/batteries';
import {
  createAsyncAction,
  asyncInitialState,
  createAsyncReducerHandlers
} from './shared/async';
import { createReducer, combineReducers } from '@reduxjs/toolkit';

export const getBattery = createAsyncAction('get-battery', getBatteryApi);

const details = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(getBattery)
);

export const battery = combineReducers({
  details
});
