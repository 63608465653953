import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FullPage from '../../../ui/FullPage/FullPage';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { getInvite } from '../../../app/invites';
import { useShallowSelector } from '../../../shared/hooks/useShallowSelector';
import { getFinishInvitePageDetailsState } from '../../../app/selectors';
import PageLoader from '../../../components/PageLoader/PageLoader';
import PageError from '../../../components/PageError/PageError';
import Typography from '@material-ui/core/Typography';
import Card from 'ui/Card';
import Container from '@material-ui/core/Container';
import FinishForm from './FinishForm';
import { completeInvite } from '../../../app/invites';
import { useNotifications } from '../../../components/Notifications/useNotifications';
import { getErrorMessage, isNotFoundError } from '../../../shared/utils/errors';
import { authenticate } from '../../../app/authentication';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    display: 'flex'
  },

  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));

const FinishInvite = () => {
  const classes = useStyles();
  const { inviteId } = useParams();
  const dispatch = useDispatch();
  const { showErrorNotification } = useNotifications();
  const { loading, error, data } = useShallowSelector(
    getFinishInvitePageDetailsState
  );
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getInvite(inviteId));
  }, [dispatch, inviteId]);

  const onInviteComplete = async values => {
    try {
      await dispatch(completeInvite(inviteId, values.password));
      await dispatch(authenticate(data.email, values.password));
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <div className={classes.container}>
      <FullPage>
        {loading && <PageLoader />}
        {error && (
          <>
            {isNotFoundError(error) ? (
              <Redirect to="/" />
            ) : (
              <PageError error={error} />
            )}
          </>
        )}

        {data && (
          <>
            <Typography variant="h6" gutterBottom>
              {t('finishInvite.title', { name: data.email })} 👋
            </Typography>
            <Typography variant="body2">
              {t('finishInvite.subtitle')}
            </Typography>
            <Container maxWidth="xs">
              <Card mt={4} p={5}>
                <FinishForm inviteId={inviteId} onComplete={onInviteComplete} />
              </Card>
            </Container>
          </>
        )}
      </FullPage>
      ;
    </div>
  );
};

export default FinishInvite;
