import { get } from './shared';

export const getReviewVenues = async () => {
  const response = await get('review/venues');

  return response.data;
};

export const getReviewStationInstallations = async () => {
  const response = await get('review/stations-installations');

  return response.data;
};
