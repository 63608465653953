import {
  getReviewVenues as getReviewVenuesApi,
  getReviewStationInstallations as getReviewStationInstallationsApi
} from '../api/review';
import {
  createAsyncAction,
  asyncInitialState,
  createAsyncReducerHandlers
} from './shared/async';
import { createReducer, combineReducers } from '@reduxjs/toolkit';

export const getReviewVenues = createAsyncAction(
  'get-review-venues',
  getReviewVenuesApi
);
export const getReviewStationInstallations = createAsyncAction(
  'get-review-stations-installations',
  getReviewStationInstallationsApi
);

export const venues = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(getReviewVenues)
);

export const stationsInstallations = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(getReviewStationInstallations)
);

export const review = combineReducers({
  venues,
  stationsInstallations
});
