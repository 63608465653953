import { post, put } from './shared';

export const endBatteryLending = async batteryLendingId => {
  await post(`batteries-lendings/${batteryLendingId}/end`);
};

export const refundBatteryLending = async (batteryLendingId, refundAmount) => {
  const response = await post(`batteries-lendings/${batteryLendingId}/refund`, {
    data: {
      cardAmount: refundAmount
    }
  });

  return response.data;
};

export const changeBatteryLendingAmount = async (batteryLendingId, amount) => {
  await put(`batteries-lendings/${batteryLendingId}/amount`, {
    data: {
      cardAmount: amount
    }
  });
};
