import React from 'react';
import NotFoundErrorImage from './images/not-found-error.svg';
import ErrorImage from './ErrorImage';
import ErrorTitle from './ErrorTitle';

const NotFoundError = () => {
  return (
    <>
      <ErrorImage src={NotFoundErrorImage} />
      <ErrorTitle>
        The page you've requested seems to be missing{' '}
        <span role="img" aria-label="searching eyes emoji">
          👀
        </span>
      </ErrorTitle>
    </>
  );
};

export default NotFoundError;
