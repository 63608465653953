export const setAuthenticatedSession = session => {
  localStorage.setItem('access_token', session.accessToken);
  localStorage.setItem('refresh_token', session.refreshToken);
  localStorage.setItem('expires_at', session.expiresAt);
};

export const clearAuthenticatedSession = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('expires_at');
};

export const hasAuthenticatedSession = () => {
  return !!getAccessToken() && !!getRefreshToken();
};

const getExpiresAt = () => {
  return localStorage.getItem('expires_at');
};

export const getAccessToken = () => {
  return localStorage.getItem('access_token');
};

export const getRefreshToken = () => {
  return localStorage.getItem('refresh_token');
};

export const isSessionExpired = () => {
  return new Date().getTime() > getExpiresAt();
};

export const isAuthenticated = () => {
  const hasAccessToken = !!getAccessToken();

  return hasAccessToken && !isSessionExpired();
};
