import axios from 'axios';

export const uploadFileToS3Url = async (file, url) => {
  const response = await axios.put(url, file, {
    headers: {
      'Content-Type': file.type
    }
  });

  return response.data;
};
