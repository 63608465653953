import React from 'react';
import Box from '../Box';
import Flex from '../Flex';
import Text from '../Text';
import { FiXCircle, FiCheck, FiX, FiAlertCircle } from 'react-icons/fi';
import IconButton from '@material-ui/core/IconButton';

const variantColors = {
  success: 'green',
  error: 'red',
  warning: 'yellow'
};

const variantIcons = {
  success: FiCheck,
  error: FiXCircle,
  warning: FiAlertCircle
};

const Alert = props => {
  const { children, variant = 'error', onClose, ...otherProps } = props;
  const variantColor = variantColors[variant];
  const Icon = variantIcons[variant];

  return (
    <Flex
      px={4}
      py={3}
      border="2px solid"
      borderColor={`${variantColor}.3`}
      variant={variant}
      borderRadius="md"
      alignItems="flex-start"
      bg={`${variantColor}.1`}
      {...otherProps}
    >
      <Box borderRadius="full" bg={`${variantColor}.2`} p={1} mr={3} mt={1}>
        <Flex
          color={`${variantColor}.8`}
          fontSize={3}
          bg="white"
          borderRadius="full"
          p={1}
        >
          <Icon />
        </Flex>
      </Box>
      <Text bold color={`${variantColor}.8`} mt={2}>
        {children}
      </Text>
      {onClose && (
        <Box ml="auto" variant={variant} color={`${variantColor}.8`}>
          <IconButton color="inherit" edge="end" onClick={onClose}>
            <Flex fontSize={4}>
              <FiX />
            </Flex>
          </IconButton>
        </Box>
      )}
    </Flex>
  );
};

export default Alert;
