import { NOT_FOUND, BAD_REQUEST, FORBIDDEN } from 'http-status-codes';
import {
  both,
  pipe,
  equals,
  path,
  map,
  flatten,
  props,
  filter,
  join
} from 'ramda';

export const isAxiosError = error => error.isAxiosError;
export const isAxiosStatus = expectedStatus =>
  pipe(path(['response', 'status']), equals(expectedStatus));

const formatValidationMessages = pipe(
  map(props(['messages', 'message'])),
  flatten,
  filter(Boolean),
  join(', ')
);

const getAxiosErrorMessage = error => {
  if (!error.response) {
    return error.message;
  }

  const details = error.response.data;

  if (details.errors) {
    return formatValidationMessages(details.errors);
  }

  return details.message || details.error || error.message;
};

export const isNotFoundError = both(isAxiosError, isAxiosStatus(NOT_FOUND));
export const isBadRequest = both(isAxiosError, isAxiosStatus(BAD_REQUEST));
export const isForbidden = both(isAxiosError, isAxiosStatus(FORBIDDEN));
export const isLazyLoaderError = error => error.name === 'ChunkLoadError';

export const getErrorMessage = error => {
  if (isAxiosError(error)) {
    return getAxiosErrorMessage(error);
  }

  return 'Something went wrong.';
};
