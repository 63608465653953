import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import Flex from 'ui/Flex';
import Text from 'ui/Text';
import Button from 'ui/Button';
import { CardActions } from 'ui/Card';
import contract from './contract_de.jpg';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { useNotifications } from 'components/Notifications/useNotifications';
import { signContractNow, signContractLater } from 'app/agents';
import { useShallowSelector } from 'shared/hooks/useShallowSelector';
import {
  getNewStationInstallationPageSignNowState,
  getNewStationInstallationPageSignLaterState
} from 'app/selectors';

const ContractImage = styled('img')`
  width: 100%;
`;

const CreationSuccess = props => {
  const { installationId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { loading: signingNow } = useShallowSelector(
    getNewStationInstallationPageSignNowState
  );
  const { loading: signingLater } = useShallowSelector(
    getNewStationInstallationPageSignLaterState
  );

  const onSignNow = async () => {
    try {
      await dispatch(signContractNow(installationId));
      showSuccessNotification(t('addStation.created.sign.success'));
    } catch (error) {
      showErrorNotification(t('addStation.created.sign.error'));
    }
  };

  const onSignLater = async () => {
    try {
      await dispatch(signContractLater(installationId));
      showSuccessNotification(t('addStation.created.sign.success'));
    } catch (error) {
      showErrorNotification(t('addStation.created.sign.error'));
    }
  };

  return (
    <>
      <Flex p={4} flexDirection="column" alignItems="center">
        <Flex textAlign="center" fontSize={9} color="green.3" pb={2}>
          <AiOutlineCheckCircle />
        </Flex>
        <Text textAlign="center" bold>
          {t('addStation.created.title')}
        </Text>
      </Flex>
      <ContractImage src={contract} />
      <CardActions justifyContent="center">
        <Button onClick={onSignNow} loading={signingNow}>
          {t('addStation.created.actions.signNow')}
        </Button>
        <Button onClick={onSignLater} loading={signingLater}>
          {t('addStation.created.actions.signLater')}
        </Button>
      </CardActions>
    </>
  );
};

export default CreationSuccess;
