import theme from '../default';

export const MuiAppBar = {
  root: {
    borderBottom: `1px solid ${theme.colors.grey[1]}`,
    // boxShadow: theme.shadows.sm
    boxShadow: theme.shadows.none
  },

  colorPrimary: {
    backgroundColor: 'white',
    color: theme.colors.text.secondary
  }
};
