import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReviewVenues, getReviewStationInstallations } from 'app/review';
import { selectForReview } from './selectors';
import { useNotifications } from '../Notifications/useNotifications';
import { getErrorMessage } from 'shared/utils/errors';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useRouterHelpers } from 'shared/hooks/useRouterHelpers';
import { FiMapPin, FiPackage } from 'react-icons/fi';
import Text from 'ui/Text';
import { rem } from 'polished';

const ReviewBadge = props => {
  return (
    <Text
      fontSize={1}
      bold
      py={rem(2)}
      px={rem(8)}
      bg="red.1"
      color="red.7"
      borderRadius="full"
    >
      {props.children}
    </Text>
  );
};

const ForReview = () => {
  const dispatch = useDispatch();
  const reviewState = useSelector(selectForReview);
  const shouldDisplayReviewSection =
    reviewState.hasAnythingToReview || reviewState.loading;
  const { showErrorNotification } = useNotifications();
  const { isActiveRoute } = useRouterHelpers();
  const hasVenuesForReview = Boolean(reviewState.data.venues);
  const hasStationsInstallationsForReview = Boolean(
    reviewState.data.stationsInstallations
  );

  const getInitialData = async () => {
    try {
      const requestVenuesForReviews = dispatch(getReviewVenues());
      const requestStationsInstallationsForReview = dispatch(
        getReviewStationInstallations()
      );

      await requestVenuesForReviews;
      await requestStationsInstallationsForReview;
    } catch (error) {
      showErrorNotification(
        `Was not able to retrieve review data: ${getErrorMessage(error)}`
      );
    }
  };

  useEffectOnce(() => {
    getInitialData();
  });

  if (!shouldDisplayReviewSection) {
    return null;
  }

  return (
    <List
      subheader={
        <ListSubheader>
          <Box display="flex" alignItems="center">
            For review{' '}
            {reviewState.loading && (
              <Box ml="auto">
                <CircularProgress size={14} color="inherit" />
              </Box>
            )}
          </Box>
        </ListSubheader>
      }
    >
      {hasVenuesForReview && (
        <ListItem
          button
          component={Link}
          to="/review/venues"
          selected={isActiveRoute('/review/venues')}
        >
          <ListItemIcon>
            <FiMapPin />
          </ListItemIcon>
          <ListItemText>Venues</ListItemText>
          <ListItemSecondaryAction>
            <ReviewBadge>{reviewState.data.venues}</ReviewBadge>
          </ListItemSecondaryAction>
        </ListItem>
      )}

      {hasStationsInstallationsForReview && (
        <ListItem
          button
          component={Link}
          to="/review/stations-installations"
          selected={isActiveRoute('/review/stations-installations')}
        >
          <ListItemIcon>
            <FiPackage />
          </ListItemIcon>
          <ListItemText>Installations</ListItemText>
          <ListItemSecondaryAction>
            <ReviewBadge color="red">
              {reviewState.data.stationsInstallations}
            </ReviewBadge>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </List>
  );
};

export default ForReview;
