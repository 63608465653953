import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  authenticate as authenticateApi,
  refreshSession as refreshSessionApi
} from '../api/authentication';
import {
  setAuthenticatedSession,
  isAuthenticated,
  clearAuthenticatedSession,
  getRefreshToken
} from '../shared/utils/authentication';
import { getMe as getMeApi } from '../api/me';
import {
  asyncInitialState,
  createAsyncReducerHandlers,
  createAsyncAction
} from './shared/async';

export const authenticated = createAction('authenticated');
export const authenticating = createAction('authenticating');
export const authenticationFailed = createAction('authentication-failed');

export const getAuthenticatedUserDetails = createAsyncAction(
  'request-authenticated-user-details',
  async dispatch => {
    try {
      const me = await getMeApi();

      dispatch(authenticated(isAuthenticated()));
      return me;
    } catch (error) {
      dispatch(logout());
    }
  }
);

const generateExpiresAtDate = expiresIn =>
  expiresIn * 1000 + new Date().getTime();

export const authenticate = (email, password) => async dispatch => {
  dispatch(authenticating());

  try {
    const data = await authenticateApi(email, password);

    setAuthenticatedSession({
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      expiresAt: generateExpiresAtDate(data.expiresInSec)
    });

    const me = await getMeApi();

    dispatch(getAuthenticatedUserDetails.success(me));
    dispatch(authenticated(true));
  } catch (error) {
    clearAuthenticatedSession();
    dispatch(authenticationFailed(error));
  }
};

export const refreshSession = () => async dispatch => {
  try {
    const refreshToken = getRefreshToken();
    const data = await refreshSessionApi(getRefreshToken());

    setAuthenticatedSession({
      accessToken: data.accessToken,
      refreshToken: refreshToken,
      expiresAt: generateExpiresAtDate(data.expiresInSec)
    });
  } catch (error) {
    dispatch(logout());
    throw error;
  }
};

export const logout = () => dispatch => {
  clearAuthenticatedSession();
  dispatch(authenticated(false));
};

const initialState = {
  isAuthenticated: false,
  error: null
};

export const authentication = createReducer(initialState, {
  [authenticated]: (state, action) => {
    state.isAuthenticated = action.payload;
  },

  [authenticating]: state => {
    state.isAuthenticated = false;
    state.error = null;
  },

  [authenticationFailed]: (state, action) => {
    state.isAuthenticated = false;
    state.error = action.payload;
  }
});

export const authenticatedUser = createReducer(asyncInitialState, {
  ...createAsyncReducerHandlers(getAuthenticatedUserDetails),

  [authenticated]: (state, action) => {
    if (!action.payload) {
      return asyncInitialState;
    }
  },

  [authenticationFailed]: () => {
    return asyncInitialState;
  }
});
