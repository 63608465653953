import React, { useEffect } from 'react';
import AuthenticatedApp from './AuthenticatedApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import {
  getAuthenticationState,
  getAuthenticatedUserState
} from './app/selectors';
import { getAuthenticatedUserDetails } from './app/authentication';
import styled from 'styled-components/macro';
import { useLoadPlacesScript } from './pages/Venues/shared/hooks/useLoadPlacesScript';
import { useShallowSelector } from './shared/hooks/useShallowSelector';
import PageErrorHandler from './components/PageErrorHandler/PageErrorHandler';
import NotAuthenticatedApp from './NotAuthenticatedApp';
import Box from 'ui/Box';

const LoadingOverlay = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const Root = () => {
  const { isAuthenticated } = useShallowSelector(getAuthenticationState);
  const authenticatedUserState = useShallowSelector(getAuthenticatedUserState);
  const { isLoaded } = useLoadPlacesScript();
  const dispatch = useDispatch();
  const shouldDisplayApplicationLoading =
    authenticatedUserState.loading || !isLoaded;

  useEffect(() => {
    dispatch(getAuthenticatedUserDetails());
  }, [dispatch]);

  if (shouldDisplayApplicationLoading) {
    return (
      <LoadingOverlay>
        <Box color="grey.5">
          <CircularProgress color="inherit" />
        </Box>
      </LoadingOverlay>
    );
  }

  return (
    <PageErrorHandler>
      {!isAuthenticated && <NotAuthenticatedApp />}
      {isAuthenticated && <AuthenticatedApp />}
    </PageErrorHandler>
  );
};

export default Root;
