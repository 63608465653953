import styled from 'styled-components/macro';
import Box from '../Box';
import { position } from 'styled-system';

const Relative = styled(Box)`
  position: relative;

  ${position};
`;

export default Relative;
