import styled, { css } from 'styled-components/macro';
import Box from '../Box';
import { variant } from 'styled-system';
import { selectStyleForProp } from '../helpers';

const noWrap = selectStyleForProp(
  'noWrap',
  css`
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: none;
  `
);

const uppercase = selectStyleForProp(
  'uppercase',
  css`
    text-transform: uppercase;
  `
);

const underline = selectStyleForProp(
  'underline',
  css`
    text-decoration: underline;
  `
);

const inline = selectStyleForProp(
  'inline',
  css`
    display: inline-block;
  `
);

const bold = variant({
  prop: 'bold',
  variants: {
    [true]: {
      fontWeight: 600
    },
    [false]: {
      fontWeight: 'normal'
    }
  }
});

const Text = styled(Box)`
  ${bold};
  ${noWrap};
  ${uppercase};
  ${underline};
  ${inline};
`;

Text.defaultProps = {
  fontFamily: 'sans',
  fontSize: 2,
  color: 'text.secondary'
};

export default Text;
