import React from 'react';
import styled from 'styled-components/macro';
import { rem } from 'polished';

const Image = styled.img`
  max-width: ${rem(400)};
  max-height: ${rem(300)};
  padding: ${rem(16)};
`;

const ErrorImage = props => {
  return <Image {...props} />;
};

export default ErrorImage;
