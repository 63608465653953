import { createSelector } from 'reselect';
import {
  getReviewVenuesState,
  getReviewStationsInstallationsState
} from '../../app/selectors';

const countItems = items => (items ? items.length : 0);

const countVenuesForReview = venues => {
  if (!venues.data) {
    return 0;
  }

  return countItems(venues.data.withoutOpeningHours);
};

const countItemsForReview = (venues, stationsInstallations) => {
  return {
    venues: countVenuesForReview(venues),
    stationsInstallations: countItems(stationsInstallations.data)
  };
};

const hasAnythingToReview = (venues, stationsInstallations) => {
  const counts = countItemsForReview(venues, stationsInstallations);

  return Boolean(counts.venues) || Boolean(counts.stationsInstallations);
};

const isLoading = (venues, stationsInstallations) => {
  return venues.loading || stationsInstallations.loading;
};

const hasError = (venues, stationsInstallations) => {
  return venues.error || stationsInstallations.error;
};

export const selectForReview = createSelector(
  getReviewVenuesState,
  getReviewStationsInstallationsState,
  (venues, stationsInstallations) => ({
    loading: isLoading(venues, stationsInstallations),
    error: hasError(venues, stationsInstallations),
    data: countItemsForReview(venues, stationsInstallations),
    hasAnythingToReview: hasAnythingToReview(venues, stationsInstallations)
  })
);
