import { get, post, put, del } from './shared';

export const getPromoCode = async code => {
  const response = await get(`promo-codes/${code}`);

  return response.data;
};

export const createPromoCode = async promoCode => {
  const response = await post('promo-codes', {
    data: promoCode
  });

  return response.data;
};

export const updatePromoCode = async (code, updatedPromoCode) => {
  const response = await put(`promo-codes/${code}`, {
    data: updatedPromoCode
  });

  return response.data;
};

export const deletePromoCode = async code => {
  await del(`promo-codes/${code}`);
};
