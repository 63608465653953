import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import AdminOnly from './components/Authorisation/AdminOnly';
import AgentOnly from './components/Authorisation/AgentOnly';
import PageLoader from 'components/PageLoader/PageLoader';
import NewInstallation from 'pages/Agent/Installation/NewInstallation/NewInstallation';

const Dashboard = lazy(() => import('./pages/Dashboard'));

const ImportStations = lazy(() => import('./pages/Stations/ImportStations'));
const StationDetails = lazy(() => import('./pages/Stations/StationDetails'));
const EditStation = lazy(() => import('./pages/Stations/EditStation'));

const VenueDetails = lazy(() => import('./pages/Venues/VenueDetails'));
const AddVenue = lazy(() => import('./pages/Venues/AddVenue'));
const EditVenue = lazy(() => import('./pages/Venues/EditVenue'));
const ImportVenues = lazy(() => import('./pages/Venues/ImportVenues'));

const AddPromoCode = lazy(() => import('./pages/PromoCodes/AddPromoCode'));
const EditPromoCode = lazy(() => import('./pages/PromoCodes/EditPromoCode'));
const PromoCodeDetails = lazy(() =>
  import('./pages/PromoCodes/PromoCodeDetails')
);

const UserDetails = lazy(() => import('./pages/User/UserDetails'));

const ReviewVenues = lazy(() => import('./pages/Review/ReviewVenues'));
const ReviewStationsInstallations = lazy(() =>
  import('./pages/Review/ReviewStationsInstallations')
);

const BatteryDetails = lazy(() => import('./pages/Battery/BatteryDetails'));

const StationInstallationDetails = lazy(() =>
  import('./pages/StationInstallation/StationInstallationDetails')
);

const AddChain = lazy(() => import('./pages/Chain/AddChain'));

const NewInvite = lazy(() => import('./pages/Invite/NewInvite'));

const Reports = lazy(() => import('./pages/Reports'));

const AddCampaign = lazy(() => import('./pages/Campaign/AddCampaign'));
const EditCampaign = lazy(() => import('./pages/Campaign/EditCampaign'));
const Campaigns = lazy(() => import('./pages/Campaign/Campaigns'));

const AuthenticatedApp = () => {
  return (
    <>
      <AdminOnly>
        <Layout>
          <Suspense fallback={<PageLoader />}>
            <Switch>
              <Route path="/" exact component={Dashboard} />
              <Route path="/stations/import" component={ImportStations} />
              <Route path="/stations/edit/:stationId" component={EditStation} />
              <Route path="/stations/:code" component={StationDetails} />
              <Route path="/venues/add" component={AddVenue} />
              <Route path="/venues/edit/:venueId" component={EditVenue} />
              <Route path="/venues/import" component={ImportVenues} />
              <Route path="/venues/:venueId" component={VenueDetails} />
              <Route path="/promo-codes/add" component={AddPromoCode} />
              <Route path="/promo-codes/edit/:code" component={EditPromoCode} />
              <Route path="/promo-codes/:code" component={PromoCodeDetails} />
              <Route path="/users/:userId" component={UserDetails} />
              <Route path="/review/venues" component={ReviewVenues} />
              <Route
                path="/review/stations-installations"
                component={ReviewStationsInstallations}
              />
              <Route path="/batteries/:batteryId" component={BatteryDetails} />
              <Route
                path="/stations-installations/:installationId"
                component={StationInstallationDetails}
              />
              <Route path="/chains/add" component={AddChain} />
              <Route path="/invite" component={NewInvite} exact />
              <Route path="/reports" component={Reports} />
              <Route path="/campaigns/add" component={AddCampaign} />
              <Route
                path="/campaigns/edit/:campaign"
                component={EditCampaign}
              />
              <Route path="/campaigns" component={Campaigns} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </Suspense>
        </Layout>
      </AdminOnly>

      <AgentOnly>
        <Switch>
          <Route path="/" exact component={NewInstallation} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </AgentOnly>
    </>
  );
};

export default AuthenticatedApp;
