import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AddStationDialog from './AddStationDialog';
import isEmpty from 'lodash/isEmpty';
import Stations from './Stations';
import find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import Button from 'ui/Button';
import { FiPlusCircle } from 'react-icons/fi';

const AddStationStep = props => {
  const { onBack, onNext, initialValues, geolocation } = props;
  const [isAddStationDialogOpen, setIsAddStationDialogOpen] = useState(false);
  const [stations, setStations] = useState(initialValues);
  const hasAnyStations = !isEmpty(stations);
  const { t } = useTranslation();

  const removeStationById = stationId => {
    const remainingStations = stations.filter(
      station => station.stationId !== stationId
    );
    setStations(remainingStations);
  };

  const openAddStationDialog = () => {
    setIsAddStationDialogOpen(true);
  };

  const closeAddStationDialog = () => {
    setIsAddStationDialogOpen(false);
  };

  const buildStationWithGeolocation = station => ({
    ...station,
    location: {
      lat: geolocation.latitude,
      long: geolocation.longitude
    }
  });

  const onStationAdded = station => {
    closeAddStationDialog();

    if (!find(stations, { stationId: station.stationId })) {
      setStations([...stations, buildStationWithGeolocation(station)]);
    }
  };

  const onComplete = () => {
    onNext(stations);
  };

  return (
    <>
      <Box mb={2}>
        <Stations stations={stations} onRemoveStation={removeStationById} />
      </Box>
      <Box align="center">
        <Button
          startIcon={<FiPlusCircle />}
          onClick={openAddStationDialog}
          data-test-id="add-station"
        >
          {t('addStation.step2.add')}
        </Button>
      </Box>
      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item>
            <Button variant="tertiary" onClick={onBack}>
              {t('common.back')}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onComplete} disabled={!hasAnyStations}>
              {t('common.next')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <AddStationDialog
        open={isAddStationDialogOpen}
        onClose={closeAddStationDialog}
        onStationAdded={onStationAdded}
      />
    </>
  );
};

export default AddStationStep;
