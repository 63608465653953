import React from 'react';
import GenericErrorImage from './images/generic-error.svg';
import ErrorImage from './ErrorImage';
import ErrorTitle from './ErrorTitle';

const GenericError = () => {
  return (
    <>
      <ErrorImage src={GenericErrorImage} />
      <ErrorTitle>
        Something is not right{' '}
        <span role="img" aria-label="thinking emoji">
          🤔
        </span>{' '}
        Please try again later.
      </ErrorTitle>
    </>
  );
};

export default GenericError;
