import { createSelector } from 'reselect';
import { prepareSearchResults } from '../../../../../shared/selectors/searchResults';
import { getNewStationInstallationPageSearchStationState } from '../../../../../app/selectors';

export const selectSearchState = createSelector(
  getNewStationInstallationPageSearchStationState,
  state => ({
    loading: state.loading,
    error: state.error,
    data: state.data && prepareSearchResults(state.data)
  })
);
