import {
  createAsyncAction,
  asyncInitialState,
  createAsyncReducerHandlers
} from './shared/async';
import { createReducer, combineReducers, createAction } from '@reduxjs/toolkit';
import {
  getVenueById as getVenueByIdApi,
  createVenue as createVenueApi,
  updateVenue as updateVenueApi,
  attachStationToVenue as attachStationToVenueApi,
  detachStationFromVenue as detachStationFromVenueApi,
  getVenuesImportUrl as getVenuesImportUrlApi,
  importVenues as importVenuesApi,
  refreshVenueOpeningTimes as refreshVenueOpeningTimesApi,
  updateVenueOpeningTimes as updateVenueOpeningTimesApi,
  getVenueActivity as getVenueActivityApi,
  getVenueRevenue as getVenueRevenueApi,
  getVenueStations as getVenueStationsApi,
  deleteVenue as deleteVenueApi
} from '../api/venues';
import has from 'lodash/has';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import {
  addIdToMetaFromFirstArgument,
  createByIdReducerHandlers
} from './shared/stateById';
import { uploadFileToS3Url } from '../api/uploader';
import { getReviewVenues } from './review';
import { searchForStation } from './search';
import { getVenuePageStationsState } from './selectors';

const STATIONS_PER_PAGE = 50;

export const getVenueById = createAsyncAction(
  'get-venue-by-id',
  getVenueByIdApi
);
export const createVenue = createAsyncAction('create-venue', createVenueApi);
export const updateVenue = createAsyncAction(
  'update-venue',
  async (venueId, values, dispatch) => {
    await updateVenueApi(venueId, values);
    await dispatch(getReviewVenues());
  }
);
export const attachStationToVenue = createAsyncAction(
  'attach-station-to-venue',
  attachStationToVenueApi
);
export const detachStationFromVenue = createAsyncAction(
  'detach-station-from-venue',
  detachStationFromVenueApi,
  addIdToMetaFromFirstArgument
);

export const importVenuesFromFile = createAsyncAction(
  'import-venues',
  async file => {
    const { key, url } = await getVenuesImportUrlApi();

    await uploadFileToS3Url(file, url);
    return importVenuesApi(key);
  }
);

export const initialiseImportVenuesPage = createAction(
  'initialize-import-venues-page'
);
export const refreshVenueOpeningTimes = createAsyncAction(
  'refresh-venue-opening-times',
  refreshVenueOpeningTimesApi
);
export const updateVenueOpeningTimes = createAsyncAction(
  'update-venue-opening-times',
  async (venueId, openings, dispatch) => {
    const updatedOpenings = await updateVenueOpeningTimesApi(venueId, openings);
    dispatch(getReviewVenues());

    return updatedOpenings;
  }
);

export const getVenueActivity = createAsyncAction(
  'get-venue-activity',
  getVenueActivityApi
);

export const getVenueRevenue = createAsyncAction(
  'get-venue-revenue',
  getVenueRevenueApi
);

export const getVenueStations = createAsyncAction(
  'get-venue-stations',
  venueId => getVenueStationsApi(venueId, STATIONS_PER_PAGE)
);

export const getVenueStationsNextPage = createAsyncAction(
  'get-venue-stations-next-page',
  (venueId, dispatch, getState) => {
    const state = getVenuePageStationsState(getState());

    return getVenueStationsApi(venueId, STATIONS_PER_PAGE, state.nextStationId);
  }
);

export const deleteVenue = createAsyncAction('delete-venue', deleteVenueApi);

const isAddingExistingStation = (stations, stationId) =>
  Boolean(find(stations, { stationId }));

const details = createReducer(asyncInitialState, {
  ...createAsyncReducerHandlers(getVenueById),

  [refreshVenueOpeningTimes.success]: (state, action) => {
    if (has(state, 'data.openings')) {
      state.data.openings = action.payload;
    }
  },

  [updateVenueOpeningTimes.success]: (state, action) => {
    if (has(state, 'data.openings')) {
      state.data.openings = action.payload;
    }
  }
});

const searchStation = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(searchForStation)
);

const attachStation = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(attachStationToVenue)
);

const detachStation = createReducer(
  {},
  createByIdReducerHandlers(
    asyncInitialState,
    createAsyncReducerHandlers(detachStationFromVenue)
  )
);

const refreshOpeningTimes = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(refreshVenueOpeningTimes)
);

const updateOpeningTimes = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(updateVenueOpeningTimes)
);

const activity = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(getVenueActivity)
);

const revenue = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(getVenueRevenue)
);

const deletingVenue = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(deleteVenue)
);

const stationsState = {
  ...asyncInitialState,
  nextPageLoading: false,
  nextPageError: null,
  isAtEnd: false
};

const stations = createReducer(stationsState, {
  [attachStationToVenue.success]: (state, action) => {
    if (!state.data) {
      return;
    }

    if (isAddingExistingStation(state.data, action.payload.stationId)) {
      return;
    }

    state.data.push(action.payload);
  },

  [detachStationFromVenue.success]: (state, action) => {
    const { id } = action.meta;

    if (state.data) {
      state.data = state.data.filter(station => station.stationId !== id);
    }
  },

  [getVenueStations.request]: (state, action) => {
    state.nextPageLoading = false;
    state.nextPageError = null;
    state.isAtEnd = false;
    state.loading = true;
    state.request = action.payload;
    state.error = null;
    state.data = null;
    state.nextStationId = undefined;
  },

  [getVenueStations.success]: (state, action) => {
    state.loading = false;
    state.data = action.payload.stations;
    state.nextStationId = action.payload.nextStationId;
    state.isAtEnd = !action.payload.nextStationId;
  },

  [getVenueStations.error]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  [getVenueStationsNextPage.request]: state => {
    state.nextPageLoading = true;
    state.nextPageError = null;
  },

  [getVenueStationsNextPage.success]: (state, action) => {
    state.nextPageLoading = false;

    if (state.data) {
      const stations = [...state.data, ...action.payload.stations];

      state.data = uniqBy(stations, 'stationId');
      state.nextStationId = action.payload.nextStationId;
      state.isAtEnd = !action.payload.nextStationId;
    }
  },

  [getVenueStationsNextPage.error]: (state, action) => {
    state.nextPageLoading = false;
    state.nextPageError = action.payload;
  }
});

export const venue = combineReducers({
  details,
  searchStation,
  attachStation,
  detachStation,
  refreshOpeningTimes,
  updateOpeningTimes,
  activity,
  revenue,
  stations,
  deletingVenue
});

export const editVenue = combineReducers({
  details
});

export const importVenues = createReducer(asyncInitialState, {
  ...createAsyncReducerHandlers(importVenuesFromFile),

  [initialiseImportVenuesPage]: () => asyncInitialState
});
