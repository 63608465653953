import React from 'react';
import { Switch, Route } from 'react-router-dom';
import FinishInvite from './pages/Invite/FinishInvite';
import Login from './pages/Login/Login';
import NewPasswordReset from 'pages/PasswordReset/NewPasswordReset';
import FinishPasswordReset from 'pages/PasswordReset/FinishPasswordReset/FinishPasswordReset';

const NotAuthenticatedApp = () => {
  return (
    <Switch>
      <Route path="/invites/:inviteId/finish" component={FinishInvite} />
      <Route path="/passwords-resets" exact component={NewPasswordReset} />
      <Route
        path="/passwords-resets/:passwordResetId/finish"
        exact
        component={FinishPasswordReset}
      />
      <Route component={Login} />
    </Switch>
  );
};

export default NotAuthenticatedApp;
