import Flex from 'ui/Flex';
import styled, { css } from 'styled-components/macro';
import { selectStyleForProp } from 'ui/helpers';

const fullHeight = selectStyleForProp(
  'fullHeight',
  css`
    height: 100%;
  `
);

const Card = styled(Flex)`
  ${fullHeight};
`;

Card.defaultProps = {
  bg: 'white',
  borderRadius: 'md',
  boxShadow: 'md',
  flexDirection: 'column'
};

export default Card;
