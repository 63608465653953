import React from 'react';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import BatteryChargingFullOutlinedIcon from '@material-ui/icons/BatteryChargingFullOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { rem } from 'polished';
import { isEmpty } from 'ramda';

const useStyles = makeStyles(theme => ({
  item: {
    height: rem(48),
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },

  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const icons = {
  station: BatteryChargingFullOutlinedIcon,
  venue: BusinessOutlinedIcon,
  promoCode: ReceiptOutlinedIcon,
  user: PermIdentityOutlinedIcon
};

const getResultIcon = type => {
  const Icon = icons[type];

  if (!Icon) {
    return null;
  }

  return <Icon size={14} color="disabled" />;
};

const SearchResults = props => {
  const classes = useStyles();
  const { loading, data, getItemProps, highlightedIndex, error } = props;

  if (error) {
    return (
      <List>
        <ListItem>
          <ListItemText className={classes.centered}>
            <Typography variant="body2" color="error">
              Something went wrong{' '}
              <span role="img" aria-label="scared">
                😱
              </span>
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
    );
  }

  if (loading || !data) {
    return (
      <List>
        <ListItem>
          <ListItemText className={classes.centered}>
            <CircularProgress size={20} />
          </ListItemText>
        </ListItem>
      </List>
    );
  }

  if (isEmpty(data)) {
    return (
      <List>
        <ListItem>
          <ListItemText className={classes.centered}>
            <Typography variant="body2" color="textSecondary">
              Nothing has been found
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
    );
  }

  return (
    <Box maxHeight={rem(360)} overflow="auto">
      <List>
        {data.map((item, index) => (
          <ListItem
            button
            {...getItemProps({
              key: item.id,
              index,
              item
            })}
            selected={highlightedIndex === index}
          >
            <ListItemText primary={item.title} secondary={item.subtitle} />
            <ListItemSecondaryAction>
              {getResultIcon(item.type)}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SearchResults;
