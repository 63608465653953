import mapValues from 'lodash/mapValues';
import produce from 'immer';
import { createReducer } from '@reduxjs/toolkit';

export const addIdToMetaFromFirstArgument = (...args) => ({
  meta: {
    id: args[0]
  }
});

export const addIdToMetaFromSecondArgument = (...args) => ({
  meta: {
    id: args[1]
  }
});

export const addIdToMetaFromThirdArgument = (...args) => ({
  meta: {
    id: args[2]
  }
});

export const createByIdReducerHandlers = (initialState, handlers) => {
  return mapValues(handlers, handler => {
    return (state, action) => {
      const stateForId = state[action.meta.id] || initialState;

      state[action.meta.id] = produce(stateForId, draft =>
        handler(draft, action)
      );
    };
  });
};

export const applyReducerToMapOfIds = (reducer, handlers) => {
  return createReducer(
    {},
    mapValues(handlers, () => {
      return (state, action) => {
        const stateForId = state[action.meta.id];
        state[action.meta.id] = reducer(stateForId, action);
      };
    })
  );
};
