import { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${themeGet('colors.grey.0')};
    letter-spacing: ${themeGet('letterSpacings.normal')};
  }
`;

export default GlobalStyle;
