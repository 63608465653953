import { getWithoutAuthorisation, postWithoutAuthorisation } from './shared';

export const createPasswordReset = async email => {
  await postWithoutAuthorisation('passwords-resets', {
    data: {
      email
    }
  });
};

export const getPasswordReset = async passwordResetId => {
  const response = await getWithoutAuthorisation(
    `passwords-resets/${passwordResetId}`
  );

  return response.data;
};

export const completePasswordReset = async (passwordResetId, password) => {
  await postWithoutAuthorisation(
    `passwords-resets/${passwordResetId}/complete`,
    {
      data: {
        password
      }
    }
  );
};
