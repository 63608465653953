import React, { useEffect, useState } from 'react';
import Flex from 'ui/Flex';
import FullPage from 'ui/FullPage/FullPage';
import { useParams, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getPasswordReset, completePasswordReset } from 'app/passwordsResets';
import { useShallowSelector } from 'shared/hooks/useShallowSelector';
import { getFinishPasswordResetPageDetailsState } from 'app/selectors';
import PageLoader from 'components/PageLoader/PageLoader';
import { isNotFoundError, getErrorMessage } from 'shared/utils/errors';
import PageError from 'components/PageError/PageError';
import Text from 'ui/Text';
import Box from 'ui/Box';
import { rem } from 'polished';
import Card from 'ui/Card';
import FinishForm from './FinishForm';
import Button from 'ui/Button';
import { Link } from 'react-router-dom';
import { useNotifications } from 'components/Notifications/useNotifications';

const FinishPasswordReset = () => {
  const [isCompleted, setIsCompleted] = useState(false);
  const { passwordResetId } = useParams();
  const dispatch = useDispatch();
  const { loading, error, data } = useShallowSelector(
    getFinishPasswordResetPageDetailsState
  );
  const { showErrorNotification } = useNotifications();

  useEffect(() => {
    dispatch(getPasswordReset(passwordResetId));
  }, [dispatch, passwordResetId]);

  const onFormComplete = async values => {
    try {
      await dispatch(completePasswordReset(passwordResetId, values.password));
      setIsCompleted(true);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <Flex minHeight="100vh">
      <FullPage>
        {loading && <PageLoader />}
        {error && (
          <>
            {isNotFoundError(error) ? (
              <Redirect to="/" />
            ) : (
              <PageError error={error} />
            )}
          </>
        )}

        {data && (
          <Box maxWidth={rem(400)} width="100%" p={4}>
            {isCompleted && (
              <>
                <Text fontSize={4} bold mb={1}>
                  All done{' '}
                  <span role="img" aria-label="">
                    🙌
                  </span>
                </Text>
                <Text lineHeight="normal" mb={7}>
                  You can now use your new password to login in to your account.
                </Text>
                <Button component={Link} to="/" fullWidth>
                  Sign In
                </Button>
              </>
            )}

            {!isCompleted && (
              <>
                <Text fontSize={4} bold mb={1}>
                  Reset your password
                </Text>
                <Text mb={4}>Please choose a new password.</Text>
                <Card>
                  <FinishForm onComplete={onFormComplete} />
                </Card>
              </>
            )}
          </Box>
        )}
      </FullPage>
    </Flex>
  );
};

export default FinishPasswordReset;
