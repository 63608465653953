import styled from 'styled-components/macro';
import Flex from 'ui/Flex';
import { themeGet } from '@styled-system/theme-get';

const CardActions = styled(Flex)`
  > * + * {
    margin-left: ${themeGet('space.3')};
  }
`;

CardActions.defaultProps = {
  py: 3,
  px: 4,
  bg: 'grey.0',
  borderBottomLeftRadius: 'md',
  borderBottomRightRadius: 'md',
  justifyContent: 'flex-end',
  flexGrow: 0
};

export default CardActions;
