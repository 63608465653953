import styled from 'styled-components/macro';
import Box from 'ui/Box';

const CardHeader = styled(Box)``;

CardHeader.defaultProps = {
  pt: 3,
  px: 4
};

export default CardHeader;
