import { createAsyncAction } from './shared/async';
import {
  endBatteryLending as endBatteryLendingApi,
  refundBatteryLending as refundBatteryLendingApi,
  changeBatteryLendingAmount as changeBatteryLendingAmountApi
} from 'api/batteriesLendings';
import { addIdToMetaFromFirstArgument } from './shared/stateById';

export const endBatteryLending = createAsyncAction(
  'end-battery-lending',
  endBatteryLendingApi,
  addIdToMetaFromFirstArgument
);

export const refundBatteryLending = createAsyncAction(
  'refund-battery-lending',
  refundBatteryLendingApi,
  addIdToMetaFromFirstArgument
);

export const changeBatteryLendingAmount = createAsyncAction(
  'change-battery-lending-amount',
  changeBatteryLendingAmountApi,
  (id, amount) => ({
    meta: { id, amount }
  })
);
