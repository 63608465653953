import React from 'react';
import ErrorHandler from '../../ui/ErrorHandler/ErrorHandler';
import PageError from '../PageError/PageError';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  errorWrapper: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const PageErrorHandler = props => {
  const classes = useStyles();

  return (
    <ErrorHandler>
      {({ hasError, error }) => (
        <>
          {hasError && (
            <div className={classes.errorWrapper}>
              <PageError error={error} />
            </div>
          )}

          {!hasError && props.children}
        </>
      )}
    </ErrorHandler>
  );
};

export default PageErrorHandler;
