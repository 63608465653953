import theme from '../default';

export const MuiDialog = {
  paper: {
    backgroundColor: 'white',
    color: theme.colors.text.secondary,
    boxShadow: theme.shadows.xl,
    borderRadius: theme.radii.md
  }
};

export const MuiDialogTitle = {
  root: {
    padding: `${theme.space[4]} ${theme.space[6]}`,
    paddingBottom: 0
  }
};

export const MuiDialogContent = {
  root: {
    marginTop: theme.space[1],
    padding: `${theme.space[4]} ${theme.space[6]}`
  }
};

export const MuiDialogActions = {
  root: {
    backgroundColor: theme.colors.grey[0],
    padding: `${theme.space[3]} ${theme.space[6]}`,
    borderBottomLeftRadius: theme.radii.md,
    borderBottomRightRadius: theme.radii.md
  }
};
