import { pipe, prop } from 'ramda';

export const getAppState = prop('app');
export const getPagesState = prop('pages');

export const getStationPageState = pipe(getPagesState, prop('station'));

export const getStationPageDetailsState = pipe(
  getStationPageState,
  prop('details')
);

export const getStationPageNotesState = pipe(
  getStationPageState,
  prop('notes')
);

export const getStationPageCreatingNoteState = pipe(
  getStationPageState,
  prop('createNote')
);

export const getStationPageAllBatteriesReleaseState = pipe(
  getStationPageState,
  prop('releaseAllBatteries')
);

export const getStationPageAllBatteriesSyncState = pipe(
  getStationPageState,
  prop('syncAllBatteries')
);

export const getStationRebootState = pipe(getStationPageState, prop('reboot'));
export const getStationLockState = pipe(getStationPageState, prop('lock'));
export const getStationUnlockState = pipe(getStationPageState, prop('unlock'));

export const getStationPageBatteriesState = pipe(
  getStationPageState,
  prop('batteries')
);

export const getStationPageSimState = pipe(getStationPageState, prop('sim'));

export const getStationPageDetachStationState = pipe(
  getStationPageState,
  prop('detachStation')
);

export const getStationPageAttachStationState = pipe(
  getStationPageState,
  prop('attachStation')
);

export const getStationPageSearchStationState = pipe(
  getStationPageState,
  prop('searchStation')
);

export const getStationPageDetachFromVenueState = pipe(
  getStationPageState,
  prop('detachFromVenue')
);

export const getImportStationsPageState = pipe(
  getPagesState,
  prop('importStations')
);

export const getEditStationPageState = pipe(getPagesState, prop('editStation'));

export const getEditStationPageDetailsState = pipe(
  getEditStationPageState,
  prop('details')
);

export const getVenuePageState = pipe(getPagesState, prop('venue'));

export const getVenuePageDetailsState = pipe(
  getVenuePageState,
  prop('details')
);

export const getVenuePageActivityState = pipe(
  getVenuePageState,
  prop('activity')
);

export const getVenuePageRevenueState = pipe(
  getVenuePageState,
  prop('revenue')
);

export const getVenuePageStationsState = pipe(
  getVenuePageState,
  prop('stations')
);

export const getVenuePageSearchStationState = pipe(
  getVenuePageState,
  prop('searchStation')
);

export const getVenuePageAttachStationState = pipe(
  getVenuePageState,
  prop('attachStation')
);

export const getVenuePageDetachStationState = pipe(
  getVenuePageState,
  prop('detachStation')
);

export const getVenuePageDeletingVenueState = pipe(
  getVenuePageState,
  prop('deletingVenue')
);

export const getVenuePageRefreshOpeningTimesState = pipe(
  getVenuePageState,
  prop('refreshOpeningTimes')
);

export const getVenuePageUpdateOpeningTimesState = pipe(
  getVenuePageState,
  prop('updateOpeningTimes')
);

export const getEditVenuePageState = pipe(getPagesState, prop('editVenue'));

export const getEditVenuePageDetailsState = pipe(
  getEditVenuePageState,
  prop('details')
);

export const getImportVenuesPageState = pipe(
  getPagesState,
  prop('importVenues')
);

export const getPromoCodePageState = pipe(getPagesState, prop('promoCode'));

export const getPromoCodePageDetailsState = pipe(
  getPromoCodePageState,
  prop('details')
);

export const getPromoCodePageDeletingState = pipe(
  getPromoCodePageState,
  prop('deletingPromoCode')
);

export const getEditPromoCodePageState = pipe(
  getPagesState,
  prop('editPromoCode')
);

export const getEditPromoCodePageDetailsState = pipe(
  getEditPromoCodePageState,
  prop('details')
);

export const getUserPageState = pipe(getPagesState, prop('user'));

export const getUserPageDetailsState = pipe(getUserPageState, prop('details'));

export const getUserPageBatteriesLendingsState = pipe(
  getUserPageState,
  prop('batteriesLendings')
);

export const getUserPagePromoCodesState = pipe(
  getUserPageState,
  prop('promoCodes')
);

export const getUserPageDeletingState = pipe(
  getUserPageState,
  prop('deletingUser')
);

export const getUserPageFixingSwapState = pipe(
  getUserPageState,
  prop('fixingSwap')
);

export const getBatteryPageState = pipe(getPagesState, prop('battery'));

export const getBatteryPageDetailsState = pipe(
  getBatteryPageState,
  prop('details')
);

export const getNewStationInstallationPageState = pipe(
  getPagesState,
  prop('newStationInstallation')
);

export const getNewStationInstallationPageSearchStationState = pipe(
  getNewStationInstallationPageState,
  prop('searchStation')
);

export const getNewStationInstallationPageCreateState = pipe(
  getNewStationInstallationPageState,
  prop('create')
);

export const getNewStationInstallationPageSignNowState = pipe(
  getNewStationInstallationPageState,
  prop('signNow')
);

export const getNewStationInstallationPageSignLaterState = pipe(
  getNewStationInstallationPageState,
  prop('signLater')
);

export const getStationInstallationPageState = pipe(
  getPagesState,
  prop('stationInstallation')
);

export const getStationInstallationPageDetailsState = pipe(
  getStationInstallationPageState,
  prop('details')
);

export const getStationInstallationPageCompleteReviewState = pipe(
  getStationInstallationPageState,
  prop('completeReview')
);

export const getStationInstallationPageUpdateContactDetailsState = pipe(
  getStationInstallationPageState,
  prop('updateContactDetails')
);

export const getFinishInvitePageState = pipe(
  getPagesState,
  prop('finishInvite')
);

export const getFinishInvitePageDetailsState = pipe(
  getFinishInvitePageState,
  prop('details')
);

export const getFinishPasswordResetPageState = pipe(
  getPagesState,
  prop('finishPasswordReset')
);

export const getFinishPasswordResetPageDetailsState = pipe(
  getFinishPasswordResetPageState,
  prop('details')
);

export const getReportsPageState = pipe(getPagesState, prop('reports'));

export const getReportsPageDownloadTookanReportState = pipe(
  getReportsPageState,
  prop('downloadTookanReport')
);

export const getDashboardPageState = pipe(getPagesState, prop('dashboard'));

export const getDashboardPageStationCountsState = pipe(
  getDashboardPageState,
  prop('stationCounts')
);

export const getDashboardPageVenuesWithStationsState = pipe(
  getDashboardPageState,
  prop('venuesWithStations')
);

export const getEditCampaignPageState = pipe(
  getPagesState,
  prop('editCampaign')
);

export const getEditCampaignPageDetailsState = pipe(
  getEditCampaignPageState,
  prop('details')
);

export const getAuthenticationState = pipe(getAppState, prop('authentication'));

export const getAuthenticatedUserState = pipe(
  getAppState,
  prop('authenticatedUser')
);

export const getSearchState = pipe(getAppState, prop('search'));

export const getChainsState = pipe(getAppState, prop('chains'));

export const getCampaignsState = pipe(getAppState, prop('campaigns'));

export const getReviewState = pipe(getAppState, prop('review'));

export const getReviewVenuesState = pipe(getReviewState, prop('venues'));

export const getReviewStationsInstallationsState = pipe(
  getReviewState,
  prop('stationsInstallations')
);
