import { useUserRoles } from '../../shared/hooks/useUserRoles';

const AdminOnly = props => {
  const { isAdmin, isOperations } = useUserRoles();

  if (!isAdmin && !isOperations) {
    return null;
  }

  return props.children;
};

export default AdminOnly;
