import { css } from 'styled-components/macro';

const noStyle = css``;

export const selectStyleForProp = (
  prop,
  propExistsStyle = noStyle,
  propNotExistsStyle = noStyle
) => props => (props[prop] ? propExistsStyle : propNotExistsStyle);

export const selectStyleForPropKey = (prop, styles) => props =>
  styles[props[prop]] || noStyle;
