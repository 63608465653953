import {
  combineReducers,
  configureStore,
  getDefaultMiddleware
} from '@reduxjs/toolkit';
import { authenticatedUser, authentication } from './app/authentication';
import { importStations, station, editStation } from './app/stations';
import { search } from './app/search';
import { chains } from './app/chains';
import { campaigns, editCampaign } from './app/campaigns';
import { editVenue, importVenues, venue } from './app/venues';
import { editPromoCode, promoCode } from './app/promoCodes';
import { user } from './app/users';
import { review } from './app/review';
import { battery } from './app/batteries';
import { newStationInstallation } from './app/agents';
import { stationInstallation } from './app/stationsInstallations';
import { finishInvite } from './app/invites';
import { finishPasswordReset } from './app/passwordsResets';
import { reports } from './app/reports';
import { dashboard } from './app/dashboard';

export const customisedStarterKitMiddleware = getDefaultMiddleware({
  serializableCheck: false,
  immutableCheck: false
});

const app = combineReducers({
  authentication,
  authenticatedUser,
  search,
  chains,
  campaigns,
  review
});

const pages = combineReducers({
  station,
  editStation,
  importStations,
  venue,
  importVenues,
  editVenue,
  promoCode,
  editPromoCode,
  user,
  battery,
  newStationInstallation,
  stationInstallation,
  finishInvite,
  finishPasswordReset,
  reports,
  dashboard,
  editCampaign
});

export const reducer = combineReducers({
  app,
  pages
});

export const store = configureStore({
  reducer,
  middleware: [...customisedStarterKitMiddleware]
});
