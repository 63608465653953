import React, { createContext, PureComponent } from 'react';
import { Formik } from 'formik';
import isFunction from 'lodash/isFunction';

export const FormContext = createContext({});

class Form extends PureComponent {
  handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    try {
      await this.props.onSubmit(values, actions);
    } finally {
      actions.setSubmitting(false);
    }
  };

  render() {
    const { children } = this.props;

    return (
      <Formik {...this.props} onSubmit={this.handleSubmit}>
        {formikProps => (
          <FormContext.Provider value={formikProps}>
            {isFunction(children) ? children(formikProps) : children}
          </FormContext.Provider>
        )}
      </Formik>
    );
  }
}

export default Form;
