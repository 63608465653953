import React from 'react';
import Flex from '../Flex';
import Text from '../Text';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FiXCircle } from 'react-icons/fi';
import Truncate from 'react-truncate';

const CardOverlay = props => {
  return (
    <Flex
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      p={4}
    >
      {props.children}
    </Flex>
  );
};

const CardDataLoading = props => {
  const { loading, error, children } = props;

  if (loading) {
    return (
      <CardOverlay>
        <Flex color="grey.5" fontSize={6}>
          <CircularProgress size="1em" color="inherit" />
        </Flex>
      </CardOverlay>
    );
  }

  if (error) {
    return (
      <CardOverlay>
        <Flex color="red.5" bg="red.1" borderRadius="full" p={2} fontSize={4}>
          <FiXCircle />
        </Flex>
        <Text color="red.5" mt={3} bold textAlign="center">
          <Truncate lines={3}>{error}</Truncate>
        </Text>
      </CardOverlay>
    );
  }

  return children;
};

export default CardDataLoading;
