import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Form from '../../../../../components/Form/Form';
import { Field, Form as FormikForm } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from 'ui/Button';

const buildFormSchema = t =>
  Yup.object().shape({
    fullName: Yup.string()
      .trim()
      .required(t('addStation.step3.form.fullName.errors.required')),
    email: Yup.string()
      .email(t('addStation.step3.form.email.errors.invalid'))
      .required(t('addStation.step3.form.email.errors.required')),
    phone: Yup.string()
      .trim()
      .required(t('addStation.step3.form.phone.errors.required'))
  });

const defaultValues = {
  fullName: '',
  email: '',
  phone: ''
};

const VenueContactStep = props => {
  const { onBack, onNext, initialValues } = props;
  const { t } = useTranslation();

  return (
    <Form
      initialValues={initialValues || defaultValues}
      onSubmit={onNext}
      validationSchema={buildFormSchema(t)}
    >
      {({ isValid, isSubmitting }) => (
        <FormikForm noValidate>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Field
                name="fullName"
                id="fullName"
                label={t('addStation.step3.form.fullName.label')}
                type="text"
                component={TextField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item>
              <Field
                name="email"
                id="email"
                label={t('addStation.step3.form.email.label')}
                type="email"
                component={TextField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                // helperText={t('addStation.step3.form.email.hint')}
              />
            </Grid>
            <Grid item>
              <Field
                name="phone"
                id="phone"
                label={t('addStation.step3.form.phone.label')}
                type="tel"
                component={TextField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={4}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="tertiary"
                  onClick={onBack}
                  disabled={isSubmitting}
                >
                  {t('common.back')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={!isValid}
                  loading={isSubmitting}
                  type="submit"
                >
                  {t('common.submit')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </FormikForm>
      )}
    </Form>
  );
};

export default VenueContactStep;
