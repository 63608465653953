import React from 'react';
import Card from 'ui/Card';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { rem } from 'polished';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles({
  icon: {
    width: rem(32),
    height: rem(32),
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  pictureIcon: {
    backgroundColor: green[100],
    color: green[600]
  },

  locationIcon: {
    backgroundColor: orange[100],
    color: orange[600]
  },

  checkBadge: {
    fontSize: rem(12),
    height: rem(16),
    width: rem(16),
    minWidth: 0,
    transform: 'scale(1) translate(25%, 25%)',
    backgroundColor: blue[700]
  }
});

const CheckBadge = props => {
  const classes = useStyles();

  return (
    <Badge
      classes={{
        badge: classes.checkBadge
      }}
      color="primary"
      badgeContent={<CheckOutlinedIcon fontSize="inherit" />}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
    >
      {props.children}
    </Badge>
  );
};

const Stations = props => {
  const { stations, onRemoveStation } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {stations.map((station, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <Card boxShadow="sm">
            <Box py={1} pl={2} pr={1}>
              <Grid
                container
                spacing={1}
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid container spacing={1} alignItems="center" wrap="nowrap">
                    <Grid item>
                      <Typography variant="h6">{station.stationId}</Typography>
                    </Grid>
                    <Grid item>
                      <CheckBadge>
                        <div
                          className={clsx([classes.icon, classes.pictureIcon])}
                        >
                          <ImageOutlinedIcon />
                        </div>
                      </CheckBadge>
                    </Grid>
                    <Grid item>
                      <CheckBadge>
                        <div
                          className={clsx([classes.icon, classes.locationIcon])}
                        >
                          <LocationOnOutlinedIcon />
                        </div>
                      </CheckBadge>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={() => onRemoveStation(station.stationId)}
                  >
                    <CancelOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Stations;
