import { useShallowSelector } from './useShallowSelector';
import { getAuthenticatedUserState } from '../../app/selectors';

const hasGroup = (data, group) => {
  if (!data) {
    return false;
  }

  return data.groups.includes(group);
};

export const useUserRoles = () => {
  const { data } = useShallowSelector(getAuthenticatedUserState);

  return {
    isAdmin: hasGroup(data, 'admin'),
    isOperations: hasGroup(data, 'operations'),
    isAgent: hasGroup(data, 'agent')
  };
};
