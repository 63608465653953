import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { rem } from 'polished';
import Camera from './camera.svg';
import { useTranslation } from 'react-i18next';

const isPermissionDeniedError = error => error.name === 'NotAllowedError';

const useStyles = makeStyles({
  cameraRequiredImage: {
    maxWidth: rem(240),
    width: '100%'
  }
});

const getCameraDetails = () =>
  window.navigator.mediaDevices.getUserMedia({
    video: {
      facingMode: {
        ideal: 'environment'
      }
    }
  });

const CheckCameraPermissions = props => {
  const [isRequestingDetails, setIsRequestingDetails] = useState(false);
  const [cameraError, setCameraError] = useState(null);
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    const performCameraPermissionVerification = async () => {
      try {
        setIsRequestingDetails(true);
        await getCameraDetails();
        setIsRequestingDetails(false);
      } catch (error) {
        setIsRequestingDetails(false);
        setCameraError(error);
      }
    };

    performCameraPermissionVerification();
  }, []);

  if (isRequestingDetails) {
    return <CircularProgress />;
  }

  if (cameraError) {
    return (
      <>
        <img
          src={Camera}
          alt="Camera failed to load"
          className={classes.cameraRequiredImage}
        />
        <Box py={2}>
          <Typography color="secondary" variant="subtitle1" align="center">
            {isPermissionDeniedError(cameraError) &&
              t('checkCameraPermission.errors.noAccess')}

            {!isPermissionDeniedError(cameraError) && cameraError.message}
          </Typography>
        </Box>
      </>
    );
  }

  return props.children;
};

export default CheckCameraPermissions;
