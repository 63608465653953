import styled from 'styled-components/macro';
import {
  compose,
  space,
  layout,
  typography,
  color,
  flexbox,
  shadow,
  border,
  variant
} from 'styled-system';

const circle = variant({
  prop: 'circle',
  variants: {
    [true]: {
      borderRadius: 'full'
    }
  }
});

const Box = styled('div')`
  box-sizing: border-box;
  margin: 0;

  ${compose(space, layout, typography, color, flexbox, shadow, border, circle)}
`;

export default Box;
