import { postWithoutAuthorisation } from './shared';

export const authenticate = async (email, password) => {
  const response = await postWithoutAuthorisation('login', {
    data: {
      email,
      password
    }
  });

  return response.data;
};

export const refreshSession = async token => {
  const response = await postWithoutAuthorisation('login/refresh', {
    data: {
      refreshToken: token
    }
  });

  return response.data;
};
