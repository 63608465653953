import {
  createAsyncAction,
  asyncInitialState,
  createAsyncReducerHandlers
} from './shared/async';
import {
  createPasswordReset as createPasswordResetApi,
  getPasswordReset as getPasswordResetApi,
  completePasswordReset as completePasswordResetApi
} from 'api/passwordsResets';
import { createReducer, combineReducers } from '@reduxjs/toolkit';

export const createPasswordReset = createAsyncAction(
  'create-password-reset',
  createPasswordResetApi
);

export const getPasswordReset = createAsyncAction(
  'get-password-reset',
  getPasswordResetApi
);

export const completePasswordReset = createAsyncAction(
  'complete-password-reset',
  completePasswordResetApi
);

const details = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(getPasswordReset)
);

export const finishPasswordReset = combineReducers({ details });
