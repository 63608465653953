import { combineReducers, createReducer } from '@reduxjs/toolkit';
import {
  asyncInitialState,
  createAsyncReducerHandlers,
  createAsyncAction
} from './shared/async';
import { searchForStation } from './search';
import {
  getStationLocationUploadUrl as getStationLocationUploadUrlApi,
  createStationInstallation as createStationInstallationApi,
  signContractLater as signContractLaterApi,
  signContractNow as signContractNowApi
} from '../api/agents';
import { uploadFileToS3Url } from '../api/uploader';

const buildStation = async station => {
  const { locationImage, ...newStation } = station;
  const { url, key } = await getStationLocationUploadUrlApi();

  await uploadFileToS3Url(locationImage.blob, url);

  return {
    ...newStation,
    locationImageKey: key
  };
};

const buildStationsWithUploadedLocationImages = async stations => {
  return Promise.all(stations.map(buildStation));
};

export const createStationInstallation = createAsyncAction(
  'create-station-installation',
  async installation => {
    const { stations, ...newInstallation } = installation;
    const installedStations = await buildStationsWithUploadedLocationImages(
      installation.stations
    );

    return createStationInstallationApi({
      ...newInstallation,
      stations: installedStations
    });
  }
);

export const signContractNow = createAsyncAction(
  'sign-contract-now',
  signContractNowApi
);

export const signContractLater = createAsyncAction(
  'sign-contract-later',
  signContractLaterApi
);

const searchStation = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(searchForStation)
);

const create = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(createStationInstallation)
);

const signNow = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(signContractNow)
);

const signLater = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(signContractLater)
);

export const newStationInstallation = combineReducers({
  searchStation,
  create,
  signNow,
  signLater
});
