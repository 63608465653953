const stationDataRegEx = /(\d+)$/;

export const isValidStationData = data => stationDataRegEx.test(data);

export const getStationIdFromData = data => {
  const matches = data.match(stationDataRegEx);

  if (!matches) {
    return null;
  }

  return matches[1];
};
