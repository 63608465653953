import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useNavigation = () => {
  const { push } = useHistory();

  const goToDashboard = useCallback(() => {
    push('/');
  }, [push]);

  const goToStationDetails = useCallback(
    code => {
      push(`/stations/${code}`);
    },
    [push]
  );

  const goToEditStation = useCallback(
    code => {
      push(`/stations/edit/${code}`);
    },
    [push]
  );

  const goToVenueDetails = useCallback(
    id => {
      push(`/venues/${id}`);
    },
    [push]
  );

  const goToEditVenue = useCallback(
    id => {
      push(`/venues/edit/${id}`);
    },
    [push]
  );

  const goToPromoCodeDetails = useCallback(
    code => {
      push(`/promo-codes/${code}`);
    },
    [push]
  );

  const goToEditPromoCode = useCallback(
    code => {
      push(`/promo-codes/edit/${code}`);
    },
    [push]
  );

  const goToUser = useCallback(
    id => {
      push(`/users/${id}`);
    },
    [push]
  );

  return {
    goToDashboard,
    goToStationDetails,
    goToEditStation,
    goToVenueDetails,
    goToEditVenue,
    goToPromoCodeDetails,
    goToEditPromoCode,
    goToUser
  };
};
