import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  searchInput: props => ({
    position: 'relative',
    borderRadius: theme.ui.radii.md,
    backgroundColor: theme.ui.colors.grey[1],
    color: theme.ui.colors.text.secondary
  }),

  searchIcon: {
    width: theme.spacing(7),
    top: 0,
    left: 0,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  inputRoot: {
    color: 'inherit',
    width: '100%'
  },

  inputInput: {
    padding: `${theme.ui.space[3]} ${theme.ui.space[9]}`,
    transition: theme.transitions.create('width'),
    fontFamily: theme.ui.fonts.sans,
    width: '100%',

    '&::placeholder': {
      color: theme.ui.colors.grey[6]
    }
  }
}));

const SearchInput = props => {
  const { openStationScanner, value, variant, actions, ...inputProps } = props;
  const classes = useStyles({ variant });

  return (
    <div className={classes.searchInput}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search..."
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        inputProps={{
          'aria-label': 'search'
        }}
        {...inputProps}
      />
      {actions}
    </div>
  );
};

export default SearchInput;
