import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import de from './de.json';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: en
  },

  de: {
    translation: de
  }
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });
