import { toHumanReadableDateFormat } from '../utils/date';

const ALLOWED_RESULTS = ['venue', 'station', 'promoCode', 'user'];

const isAllowed = result => ALLOWED_RESULTS.includes(result.type);

const toStationResult = ({ data }) => ({
  id: data.stationId,
  type: 'station',
  title: data.stationId,
  subtitle: data.mac
});

const toVenueResult = ({ data }) => ({
  id: data.venueId,
  type: 'venue',
  title: data.name,
  subtitle: data.address
});

const toPromoCode = ({ data }) => {
  return {
    id: data.promoCode,
    type: 'promoCode',
    title: data.promoCode,
    subtitle: `Valid until: ${toHumanReadableDateFormat(data.expiresAt)}`
  };
};

const toUser = ({ data }) => {
  return {
    id: data.userId,
    type: 'user',
    title: data.name,
    subtitle: data.phone
  };
};

const resultConverters = {
  venue: toVenueResult,
  station: toStationResult,
  promoCode: toPromoCode,
  user: toUser
};

export const prepareSearchResults = results => {
  return results
    .filter(isAllowed)
    .map(result => resultConverters[result.type](result));
};
