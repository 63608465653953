import { get, post, put, del } from './shared';

export const getStationByCode = async stationCode => {
  const response = await get(`stations/${stationCode}`);

  return response.data;
};

export const updateStation = async (stationId, stationUpdates) => {
  const response = await put(`stations/${stationId}`, {
    data: stationUpdates
  });

  return response.data;
};

export const releaseAllStationBatteries = async stationCode => {
  await post(`stations/${stationCode}/batteries/release`);
};

export const syncAllStationBatteries = async stationCode => {
  await post(`stations/${stationCode}/batteries/sync`);
};

export const releaseStationBatterySlot = async (stationCode, slotNumber) => {
  await post(`stations/${stationCode}/batteries/${slotNumber}/release`);
};

export const disableStationBatterySlot = async (stationCode, slotNumber) => {
  await post(`stations/${stationCode}/batteries/${slotNumber}/disable`);
};

export const enableStationBatterySlot = async (stationCode, slotNumber) => {
  await post(`stations/${stationCode}/batteries/${slotNumber}/enable`);
};

export const rebootStation = async stationCode => {
  await post(`stations/${stationCode}/reboot`);
};

export const lockStation = async stationCode => {
  await post(`stations/${stationCode}/lock`);
};

export const unlockStation = async stationCode => {
  await post(`stations/${stationCode}/unlock`);
};

export const getStationImportUrl = async () => {
  const response = await get('stations/import/url');

  return response.data;
};

export const importStations = async importFileKey => {
  const response = await post('stations/import', {
    data: {
      key: importFileKey
    }
  });

  return response.data;
};

export const getStationNotes = async stationCode => {
  const response = await get(`stations/${stationCode}/notes`);

  return response.data;
};

export const crateStationNote = async (stationCode, note) => {
  const response = await post(`stations/${stationCode}/notes`, {
    data: { note }
  });

  return response.data;
};

export const getStationSim = async stationCode => {
  const response = await get(`stations/${stationCode}/sim`);

  return response.data;
};

export const detachChildStationFromMediumStation = async (
  childStationId,
  mediumStationId
) => {
  await del(`stations/${mediumStationId}/stations/${childStationId}`);
};

export const attachChildStationToMediumStation = async (
  childStationId,
  mediumStationId,
  stationNumber
) => {
  const response = await post(
    `stations/${mediumStationId}/stations/${childStationId}`,
    {
      data: {
        stationNumber
      }
    }
  );

  return response.data;
};
