import { combineReducers, createAction, createReducer } from '@reduxjs/toolkit';
import {
  asyncInitialState,
  createAsyncAction,
  createAsyncReducerHandlers
} from './shared/async';
import {
  addIdToMetaFromSecondArgument,
  createByIdReducerHandlers,
  addIdToMetaFromFirstArgument,
  addIdToMetaFromThirdArgument
} from './shared/stateById';
import {
  getStationByCode as getStationByCodeApi,
  updateStation as updateStationApi,
  getStationImportUrl as getStationImportUrlApi,
  importStations as importStationsApi,
  rebootStation as rebootStationApi,
  releaseAllStationBatteries as releaseAllStationBatteriesApi,
  releaseStationBatterySlot as releaseStationBatterySlotApi,
  syncAllStationBatteries as syncAllStationBatteriesApi,
  getStationNotes as getStationNotesApi,
  crateStationNote as createStationNoteApi,
  getStationSim as getStationSimApi,
  detachChildStationFromMediumStation as detachChildStationFromMediumStationApi,
  attachChildStationToMediumStation as attachChildStationToMediumStationApi,
  lockStation as lockStationApi,
  unlockStation as unlockStationApi,
  disableStationBatterySlot as disableStationBatterySlotApi,
  enableStationBatterySlot as enableStationBatterySlotApi
} from '../api/stations';
import { uploadFileToS3Url } from '../api/uploader';
import find from 'lodash/find';
import has from 'lodash/has';
import get from 'lodash/get';
import range from 'lodash/range';
import { searchForStation } from './search';
import { detachStationFromVenue } from './venues';

export const getStationByCode = createAsyncAction(
  'get-station-by-id',
  getStationByCodeApi
);

export const getStationNotes = createAsyncAction(
  'get-station-notes',
  getStationNotesApi
);

export const updateStation = createAsyncAction(
  'update-station',
  updateStationApi
);

export const createStationNote = createAsyncAction(
  'create-station-note',
  createStationNoteApi
);

export const releaseAllStationBatteries = createAsyncAction(
  'release-all-station-batteries',
  releaseAllStationBatteriesApi
);

export const syncAllStationBatteries = createAsyncAction(
  'sync-all-station-batteries',
  syncAllStationBatteriesApi
);

export const releaseStationBatterySlot = createAsyncAction(
  'release-station-battery-slot',
  releaseStationBatterySlotApi,
  addIdToMetaFromSecondArgument
);

export const disableStationBatterySlot = createAsyncAction(
  'disable-station-battery-slot',
  disableStationBatterySlotApi,
  addIdToMetaFromSecondArgument
);

export const enableStationBatterySlot = createAsyncAction(
  'enable-station-battery-slot',
  enableStationBatterySlotApi,
  addIdToMetaFromSecondArgument
);

export const rebootStation = createAsyncAction(
  'reboot-station',
  rebootStationApi
);

export const lockStation = createAsyncAction('lock-station', lockStationApi);

export const unlockStation = createAsyncAction(
  'unlock-station',
  unlockStationApi
);

export const importStationsFromFile = createAsyncAction(
  'import-stations',
  async file => {
    const { key, url } = await getStationImportUrlApi();

    await uploadFileToS3Url(file, url);

    return importStationsApi(key);
  }
);

export const getStationSim = createAsyncAction(
  'get-station-sim',
  getStationSimApi
);

export const initialiseImportStationsPage = createAction(
  'initialize-import-stations-page'
);

export const detachChildStationFromMediumStation = createAsyncAction(
  'detach-child-station-from-medium-station',
  detachChildStationFromMediumStationApi,
  addIdToMetaFromFirstArgument
);

export const attachChildStationToMediumStation = createAsyncAction(
  'attach-child-station-to-medium-station',
  attachChildStationToMediumStationApi,
  addIdToMetaFromThirdArgument
);

const details = createReducer(asyncInitialState, {
  ...createAsyncReducerHandlers(getStationByCode),
  [rebootStation.success]: state => {
    if (state.data) {
      state.data.status = 2;
      state.data.emptySlotCount = 0;
      state.data.batteryCount = 0;
      state.data.usableBattery = 0;
    }
  },

  [detachChildStationFromMediumStation.success]: (state, action) => {
    const { id } = action.meta;

    if (has(state, 'data.children')) {
      state.data.children = state.data.children.filter(
        station => station.stationId !== id
      );
    }

    if (get(state, 'data.stationId') === action.meta.id) {
      state.data.venue = undefined;
    }
  },

  [attachChildStationToMediumStation.success]: (state, action) => {
    if (has(state, 'data.children')) {
      state.data.children.push(action.payload);
    }
  },

  [lockStation.success]: state => {
    if (state.data) {
      state.data.locked = true;
    }
  },

  [unlockStation.success]: state => {
    if (state.data) {
      state.data.locked = false;
    }
  },

  [detachStationFromVenue.success]: state => {
    if (state.data) {
      state.data.venue = undefined;
    }
  }
});

const releaseAllBatteries = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(releaseAllStationBatteries)
);

const syncAllBatteries = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(syncAllStationBatteries)
);

const reboot = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(rebootStation)
);

const lock = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(lockStation)
);

const unlock = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(unlockStation)
);

const updateBattery = (batteries, slot, updates) =>
  batteries.map(battery => {
    if (battery.slot === slot) {
      return { ...battery, ...updates };
    }

    return battery;
  });

const generateBatterySlotsForStation = station => {
  const totalAmountOfSlots = station.batteryCount + station.emptySlotCount;

  return range(0, totalAmountOfSlots).map(number => {
    const slot = number + 1;
    const battery = find(station.batteries, { slot });
    const slotDetails = find(station.slots, { slot });
    const isEnabled = get(slotDetails, 'isEnabled', true);

    return {
      slot,
      isReleasing: false,
      isDisabling: false,
      isEnabled,
      ...battery
    };
  });
};

const batteries = createReducer([], {
  [getStationByCode.success]: (state, action) => {
    return generateBatterySlotsForStation(action.payload);
  },

  [releaseStationBatterySlot.request]: (state, action) => {
    return updateBattery(state, action.meta.id, { isReleasing: true });
  },

  [releaseStationBatterySlot.success]: (state, action) => {
    return updateBattery(state, action.meta.id, { isReleasing: false });
  },

  [releaseStationBatterySlot.error]: (state, action) => {
    return updateBattery(state, action.meta.id, { isReleasing: false });
  },

  [disableStationBatterySlot.request]: (state, action) => {
    return updateBattery(state, action.meta.id, { isDisabling: true });
  },

  [disableStationBatterySlot.success]: (state, action) => {
    return updateBattery(state, action.meta.id, {
      isEnabled: false,
      isDisabling: false
    });
  },

  [disableStationBatterySlot.error]: (state, action) => {
    return updateBattery(state, action.meta.id, { isDisabling: false });
  },

  [enableStationBatterySlot.request]: (state, action) => {
    return updateBattery(state, action.meta.id, { isEnabling: true });
  },

  [enableStationBatterySlot.success]: (state, action) => {
    return updateBattery(state, action.meta.id, {
      isEnabled: true,
      isEnabling: false
    });
  },

  [enableStationBatterySlot.error]: (state, action) => {
    return updateBattery(state, action.meta.id, { isEnabling: false });
  }
});

const createNote = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(createStationNote)
);

const notes = createReducer(asyncInitialState, {
  ...createAsyncReducerHandlers(getStationNotes),

  [createStationNote.success]: (state, action) => {
    if (state.data) {
      state.data.unshift(action.payload);
    }
  }
});

const sim = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(getStationSim)
);

const detachStation = createReducer(
  {},
  createByIdReducerHandlers(
    asyncInitialState,
    createAsyncReducerHandlers(detachChildStationFromMediumStation)
  )
);

const attachStation = createReducer(
  {},
  createByIdReducerHandlers(
    asyncInitialState,
    createAsyncReducerHandlers(attachChildStationToMediumStation)
  )
);

const searchStation = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(searchForStation)
);

const detachFromVenue = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(detachStationFromVenue)
);

export const station = combineReducers({
  details,
  releaseAllBatteries,
  batteries,
  reboot,
  lock,
  unlock,
  syncAllBatteries,
  notes,
  createNote,
  sim,
  detachStation,
  attachStation,
  searchStation,
  detachFromVenue
});

export const importStations = createReducer(asyncInitialState, {
  ...createAsyncReducerHandlers(importStationsFromFile),

  [initialiseImportStationsPage]: () => asyncInitialState
});

export const editStation = combineReducers({
  details
});
