import React, { useState } from 'react';
import FullPage from 'ui/FullPage/FullPage';
import Flex from 'ui/Flex';
import Box from 'ui/Box';
import Card from 'ui/Card';
import Text from 'ui/Text';
import { rem } from 'polished';
import EmailForm from './EmailForm';
import { useDispatch } from 'react-redux';
import { createPasswordReset } from 'app/passwordsResets';
import { useNotifications } from 'components/Notifications/useNotifications';
import { getErrorMessage } from 'shared/utils/errors';

const NewPasswordReset = () => {
  const [isCompleted, setIsCompleted] = useState(false);
  const dispatch = useDispatch();
  const { showErrorNotification } = useNotifications();

  const onFormComplete = async values => {
    try {
      await dispatch(createPasswordReset(values.email));
      setIsCompleted(true);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <Flex minHeight="100vh">
      <FullPage>
        <Box maxWidth={rem(400)} width="100%" p={4}>
          {isCompleted && (
            <>
              <Text fontSize={4} bold mb={1}>
                Almost there, check your inbox!{' '}
                <span role="img" aria-label="mail">
                  📧
                </span>
              </Text>
              <Text lineHeight="normal">
                We've sent instructions to reset your password. Please check
                your email.
              </Text>
            </>
          )}

          {!isCompleted && (
            <>
              <Text fontSize={4} bold mb={1}>
                Forgot your password
              </Text>
              <Text mb={4}>Enter the email associated with your account.</Text>
              <Card>
                <EmailForm onComplete={onFormComplete} />
              </Card>
            </>
          )}
        </Box>
      </FullPage>
    </Flex>
  );
};

export default NewPasswordReset;
