import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '../../../../../ui/DialogTitle/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import SearchInputWithStationScanner from '../../../../../components/SearchInput/SearchInputWithStationScanner';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { searchForStation } from '../../../../../app/search';
import { selectSearchState } from './selectors';
import CameraDialog from './CameraDialog';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import Button from 'ui/Button';
import { FiCamera } from 'react-icons/fi';

const useStyles = makeStyles({
  stepper: {
    padding: 0
  },

  locationImage: {
    maxWidth: '100%',
    maxHeight: rem(400)
  }
});

const AddStationDialog = props => {
  const { open, onClose, onStationAdded } = props;
  const [selectedStation, setSelectedStation] = useState(null);
  const [locationImage, setLocationImage] = useState(null);
  const [isCameraDialogOpen, setIsCameraDialogOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchState = useSelector(selectSearchState);
  const hasAllNecessaryDetails =
    Boolean(selectedStation) && Boolean(locationImage);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedStation(null);
    setLocationImage(null);
    setCurrentStep(0);
  }, [open]);

  const onSearch = searchValue => {
    dispatch(searchForStation(searchValue, { isAssociated: false }));
  };

  const onStationAdd = () => {
    onStationAdded({
      locationImage,
      stationId: selectedStation.id
    });
  };

  const onSelected = result => {
    setSelectedStation(result);
    setCurrentStep(1);
  };

  const onLocationPictureTaken = image => {
    closeCameraDialog();
    setLocationImage(image);
  };

  const openCameraDialog = () => {
    setIsCameraDialogOpen(true);
  };

  const closeCameraDialog = () => {
    setIsCameraDialogOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle onClose={onClose}>
        {t('addStation.step2.dialog.title')}
      </DialogTitle>
      <DialogContent>
        <Stepper
          orientation="vertical"
          className={classes.stepper}
          activeStep={currentStep}
        >
          <Step>
            <StepLabel>{t('addStation.step2.dialog.step1.title')}</StepLabel>
            <StepContent>
              <SearchInputWithStationScanner
                variant="dark"
                autoFocus
                onSearch={onSearch}
                onSelected={onSelected}
                searchState={searchState}
              />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>{t('addStation.step2.dialog.step2.title')}</StepLabel>
            <StepContent>
              {locationImage && (
                <Box align="center" pb={2}>
                  <img
                    src={locationImage.dataUrl}
                    alt="Location of the station in the venue"
                    className={classes.locationImage}
                  />
                </Box>
              )}
              <Box align="center">
                <Button startIcon={<FiCamera />} onClick={openCameraDialog}>
                  {t('addStation.step2.dialog.step2.takePhoto')}
                </Button>
              </Box>
              <CameraDialog
                open={isCameraDialogOpen}
                onClose={closeCameraDialog}
                onPictureTaken={onLocationPictureTaken}
              />
            </StepContent>
          </Step>
        </Stepper>
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onStationAdd} disabled={!hasAllNecessaryDetails}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddStationDialog;
