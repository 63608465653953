import axios from 'axios';
import { mergeDeepLeft } from 'ramda';
import {
  getAccessToken,
  isSessionExpired,
  hasAuthenticatedSession
} from '../shared/utils/authentication';
import { refreshSession } from '../app/authentication';

const buildApiUrl = endpoint => `${process.env.REACT_APP_API_URL}/${endpoint}`;

const withAuthorisationHandler = client => async (endpoint, options) => {
  if (hasAuthenticatedSession() && isSessionExpired()) {
    const { store } = await import('../store');
    await store.dispatch(refreshSession());
  }

  return client(endpoint, options);
};

const buildOptions = (options = {}) => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return options;
  }

  return mergeDeepLeft(
    {
      headers: {
        Authorization: accessToken
      }
    },
    options
  );
};

const buildApiClient = method => (endpoint, options) =>
  axios({
    method,
    url: buildApiUrl(endpoint),
    ...buildOptions(options)
  });

export const get = withAuthorisationHandler(buildApiClient('get'));
export const post = withAuthorisationHandler(buildApiClient('post'));
export const put = withAuthorisationHandler(buildApiClient('put'));
export const del = withAuthorisationHandler(buildApiClient('delete'));

export const getWithoutAuthorisation = buildApiClient('get');
export const postWithoutAuthorisation = buildApiClient('post');
