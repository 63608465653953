import React, { useState, useMemo, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Card from 'ui/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popper from '@material-ui/core/Popper';
import PlacesAutocomplete from 'react-places-autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { rem } from 'polished';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isEmpty } from 'ramda';

const useStyles = makeStyles(theme => ({
  loading: {
    height: rem(48),
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },

  dropdown: {
    zIndex: theme.zIndex.tooltip,
    marginTop: theme.spacing(1)
  },

  list: {
    display: 'inline-block',
    width: '100%'
  }
}));

const AddressField = props => {
  const {
    field,
    form,
    onAddressSelected,
    searchOptions,
    ...inputProps
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const error = form.touched[field.name] && form.errors[field.name];
  const inputRef = useRef(null);
  const textFieldRef = useRef(null);
  const classes = useStyles();

  const onChange = value => {
    form.setFieldValue(field.name, value);
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = event => {
    field.onBlur(event);
    setIsFocused(false);
  };

  const showSuggestions = useMemo(() => {
    return isFocused && Boolean(field.value);
  }, [isFocused, field.value]);

  const handleSelect = address => {
    if (onAddressSelected) {
      onAddressSelected(address);
    }

    onChange(address);
  };

  return (
    <PlacesAutocomplete
      onChange={onChange}
      value={field.value}
      onSelect={handleSelect}
      debounce={500}
      searchOptions={searchOptions}
      highlightFirstSuggestion
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const shouldDisplayDropdown = loading || !isEmpty(suggestions);

        return (
          <Box position="relative">
            <TextField
              {...field}
              {...inputProps}
              {...getInputProps()}
              onBlur={onBlur}
              onFocus={onFocus}
              inputRef={inputRef}
              ref={textFieldRef}
              helperText={error}
              error={Boolean(error)}
              disabled={form.isSubmitting}
            />

            <Popper
              open={showSuggestions}
              anchorEl={inputRef.current}
              placement="bottom-start"
              className={classes.dropdown}
              style={{
                width: textFieldRef.current && textFieldRef.current.clientWidth
              }}
            >
              {shouldDisplayDropdown && (
                <Card boxShadow="lg">
                  <List className={classes.list}>
                    {loading && (
                      <ListItem>
                        <ListItemText>
                          <div className={classes.loading}>
                            <CircularProgress size={20} />
                          </div>
                        </ListItemText>
                      </ListItem>
                    )}

                    {!loading &&
                      suggestions.map(suggestion => (
                        <ListItem
                          button
                          key={suggestion.id}
                          {...getSuggestionItemProps(suggestion)}
                          selected={suggestion.active}
                        >
                          <ListItemText>{suggestion.description}</ListItemText>
                        </ListItem>
                      ))}
                  </List>
                </Card>
              )}
            </Popper>
          </Box>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default AddressField;
