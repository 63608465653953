import React from 'react';
import { useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import Notification from './Notification';
import { useScreenSizes } from 'shared/hooks/useScreenSizes';

export const useNotificationsManager = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [notifications, setNotifications] = useState([]);
  const { isMobileScreen } = useScreenSizes();

  const addNotification = useCallback(
    (message, type) => {
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: isMobileScreen ? 'center' : 'right'
        },
        autoHideDuration: 10000,
        content: (key, message) => (
          <Notification type={type} onClose={() => closeSnackbar(key)}>
            {message}
          </Notification>
        )
      });
    },
    [enqueueSnackbar, closeSnackbar, isMobileScreen]
  );

  const showSuccessNotification = useCallback(
    message => {
      addNotification(message, 'success');
    },
    [addNotification]
  );

  const showErrorNotification = useCallback(
    message => {
      addNotification(message, 'error');
    },
    [addNotification]
  );

  const closeNotification = useCallback(
    id => {
      const notificationsWithoutSpecifiedOne = notifications.filter(
        notification => notification.id !== id
      );

      setNotifications(notificationsWithoutSpecifiedOne);
    },
    [notifications]
  );

  return {
    notifications,
    closeNotification,
    showSuccessNotification,
    showErrorNotification
  };
};
