import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FullPage = props => {
  return (
    <Wrapper data-test-id={props['data-test-id']}>{props.children}</Wrapper>
  );
};

export default FullPage;
