import React from 'react';
import Form from '../../../components/Form/Form';
import { TextField } from 'formik-material-ui';
import { Field, Form as FormikForm } from 'formik';
import Button from 'ui/Button';
import Box from '@material-ui/core/Box';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const buildFormValidationSchema = t =>
  Yup.object().shape({
    password: Yup.string()
      .min(8, t('finishInvite.form.password.errors.min'))
      .matches(/[a-z]/, t('finishInvite.form.password.errors.lowercase'))
      .matches(/[A-Z]/, t('finishInvite.form.password.errors.uppercase'))
      .matches(/\d/, t('finishInvite.form.password.errors.digit'))
      .matches(/[^a-zA-Z\d]/, t('finishInvite.form.password.errors.symbol'))
      .required(t('finishInvite.form.password.errors.required'))
  });

const FinishForm = props => {
  const { onComplete } = props;
  const { t } = useTranslation();

  return (
    <div>
      <Form
        initialValues={{
          password: ''
        }}
        validationSchema={buildFormValidationSchema(t)}
        onSubmit={onComplete}
      >
        {({ isValid, isSubmitting }) => (
          <FormikForm noValidate>
            <Field
              name="password"
              id="password"
              type="password"
              label={t('finishInvite.form.password.label')}
              component={TextField}
              variant="outlined"
              margin="normal"
              fullWidth
              autoFocus
            />
            <Box mt={3} mb={2}>
              <Button type="submit" fullWidth loading={isSubmitting}>
                {t('finishInvite.submit')}
              </Button>
            </Box>
          </FormikForm>
        )}
      </Form>
    </div>
  );
};

export default FinishForm;
