import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import QrReader from 'react-qr-reader';
import Alert from 'ui/Alert';
import DialogTitle from '../../ui/DialogTitle/DialogTitle';
import {
  isValidStationData,
  getStationIdFromData
} from '../../shared/utils/stationqr';

const StationQrScannerDialog = props => {
  const { open, onClose, onStationFound } = props;
  const [error, setError] = useState(null);

  const onSuccessfulScan = data => {
    if (!data) {
      return;
    }

    if (!isValidStationData(data)) {
      setError("Can't recognise the QR code");
      return;
    }

    setError(null);
    onStationFound(getStationIdFromData(data));
  };

  const onFailedScan = error => {
    setError(error.message);
  };

  const handleClose = () => {
    setError(null);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="station-qr-scanner"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle onClose={handleClose}>Scan QR code</DialogTitle>
      <DialogContent>
        {error && (
          <Alert variant="error" mb={5}>
            {error}
          </Alert>
        )}
        <QrReader
          delay={300}
          onScan={onSuccessfulScan}
          onError={onFailedScan}
        />
      </DialogContent>
    </Dialog>
  );
};

export default StationQrScannerDialog;
