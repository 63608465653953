import React, { useCallback } from 'react';
import Button from 'ui/Button';
import Form from 'components/Form/Form';
import { TextField } from 'formik-material-ui';
import { Field, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { authenticate } from 'app/authentication';
import { getAuthenticationState } from 'app/selectors';
import { isNotFoundError, getErrorMessage } from 'shared/utils/errors';
import Alert from 'ui/Alert';
import Flex from 'ui/Flex';
import FullPage from 'ui/FullPage/FullPage';
import Box from 'ui/Box';
import Text from 'ui/Text';
import Card, { CardBody, CardActions } from 'ui/Card';
import Logo from 'components/Logo';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { themeGet } from '@styled-system/theme-get';

const ForgotPasswordLink = styled(Link)`
  color: ${themeGet('colors.text.secondary')};
`;

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
  password: Yup.string().required()
});

const getLoginErrorMessage = error => {
  if (isNotFoundError(error)) {
    return 'Invalid email or password';
  }

  return getErrorMessage(error);
};

const Login = () => {
  const dispatch = useDispatch();
  const { error } = useSelector(getAuthenticationState);

  const login = useCallback(
    async values => {
      const { email, password } = values;

      await dispatch(authenticate(email, password));
    },
    [dispatch]
  );

  return (
    <Flex minHeight="100vh">
      <FullPage>
        <Box maxWidth={rem(400)} p={4} width="100%">
          <Flex justifyContent="center" mb={6}>
            <Logo width={rem(200)} />
          </Flex>
          <Card>
            <CardBody mb={2}>
              {error && (
                <Alert variant="error" my={4} width="100%">
                  {getLoginErrorMessage(error)}
                </Alert>
              )}

              <Form
                initialValues={{ email: '', password: '' }}
                validationSchema={LoginSchema}
                onSubmit={login}
              >
                {({ isSubmitting }) => (
                  <FormikForm noValidate>
                    <Field
                      name="email"
                      id="email"
                      label="Email Address"
                      type="email"
                      component={TextField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="email"
                    />
                    <Field
                      name="password"
                      id="password"
                      label="Password"
                      type="password"
                      component={TextField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="current-password"
                    />
                    <Box mt={4}>
                      <Button type="submit" fullWidth loading={isSubmitting}>
                        Sign In
                      </Button>
                    </Box>
                  </FormikForm>
                )}
              </Form>
            </CardBody>
            <CardActions justifyContent="center">
              <ForgotPasswordLink to="/passwords-resets">
                <Text bold fontSize={1} my={1}>
                  Forgot your password?
                </Text>
              </ForgotPasswordLink>
            </CardActions>
          </Card>
        </Box>
      </FullPage>
    </Flex>
  );
};

export default Login;
