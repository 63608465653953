import React from 'react';
import FullPage from '../../ui/FullPage/FullPage';
import GenericError from './GenericError';
import NotFoundError from './NotFoundError';
import {
  isNotFoundError,
  isForbidden,
  isLazyLoaderError
} from '../../shared/utils/errors';
import AccessDenied from './AccessDenied';
import AppUpgradeAvailable from './AppUpgradeAvailable';

const getErrorDetails = error => {
  if (isNotFoundError(error)) {
    return <NotFoundError />;
  }

  if (isForbidden(error)) {
    return <AccessDenied />;
  }

  if (isLazyLoaderError(error)) {
    return <AppUpgradeAvailable />;
  }

  return <GenericError />;
};

const PageError = props => {
  const { error } = props;

  return (
    <FullPage data-test-id="page-error">{getErrorDetails(error)}</FullPage>
  );
};

export default PageError;
