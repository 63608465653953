import { get, post } from './shared';

export const getChains = async () => {
  const response = await get('chains');

  return response.data;
};

export const createChain = async chain => {
  const response = await post('chains', {
    data: chain
  });

  return response.data;
};
