import { get, post } from './shared';

export const findStationCountsInLocation = async location => {
  const response = await get('maps/stations/counts', {
    params: location
  });

  return response.data;
};

export const findVenuesWithStationsInLocation = async location => {
  const response = await post('maps/venues/search', {
    data: location
  });

  return response.data;
};
