import styled, { css } from 'styled-components/macro';
import Box from 'ui/Box';
import { selectStyleForProp } from 'ui/helpers';

const center = selectStyleForProp(
  'center',
  css`
    display: flex;
    align-items: center;
    justify-content: center;
  `
);

const CardBody = styled(Box)`
  ${center};
`;

CardBody.defaultProps = {
  py: 3,
  px: 4,
  flex: 1
};

export default CardBody;
