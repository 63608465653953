import {
  createAsyncAction,
  asyncInitialState,
  createAsyncReducerHandlers
} from './shared/async';
import {
  getInvite as getInviteApi,
  completeInvite as completeInviteApi,
  sendInvite as sendInviteApi
} from '../api/invites';
import { createReducer, combineReducers } from '@reduxjs/toolkit';

export const getInvite = createAsyncAction('get-invite', getInviteApi);
export const completeInvite = createAsyncAction(
  'complete-invite',
  completeInviteApi
);
export const sendInvite = createAsyncAction('send-invite', sendInviteApi);

const details = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(getInvite)
);

const finishingInvite = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(completeInvite)
);

export const finishInvite = combineReducers({
  details,
  finishingInvite
});
