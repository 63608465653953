import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Link } from 'react-router-dom';
import ForReview from './ForReview';
import { useRouterHelpers } from 'shared/hooks/useRouterHelpers';
import {
  FiLink,
  FiGift,
  FiMapPin,
  FiBatteryCharging,
  FiMail,
  FiAward
} from 'react-icons/fi';
import Relative from 'ui/Relative';
import { rem } from 'polished';

const Navigation = () => {
  const { isActiveRoute } = useRouterHelpers();

  return (
    <>
      <List subheader={<ListSubheader>Add</ListSubheader>}>
        <ListItem
          button
          component={Link}
          to="/venues/add"
          selected={isActiveRoute('/venues/add')}
        >
          <ListItemIcon>
            <FiMapPin />
          </ListItemIcon>
          <ListItemText>New venue</ListItemText>
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/promo-codes/add"
          selected={isActiveRoute('/promo-codes/add')}
        >
          <ListItemIcon>
            <FiGift />
          </ListItemIcon>
          <ListItemText>New promo code</ListItemText>
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/chains/add"
          selected={isActiveRoute('/chains/add')}
        >
          <ListItemIcon>
            <FiLink />
          </ListItemIcon>
          <ListItemText>New chain</ListItemText>
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/campaigns/add"
          selected={isActiveRoute('/campaigns/add')}
        >
          <ListItemIcon>
            <FiAward />
          </ListItemIcon>
          <ListItemText>New campaign</ListItemText>
        </ListItem>
      </List>
      <List subheader={<ListSubheader>Import</ListSubheader>}>
        <ListItem
          button
          component={Link}
          to="/stations/import"
          selected={isActiveRoute('/stations/import')}
        >
          <ListItemIcon>
            <Relative top={rem(4)}>
              <FiBatteryCharging />
            </Relative>
          </ListItemIcon>
          <ListItemText>Import stations</ListItemText>
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/venues/import"
          selected={isActiveRoute('/venues/import')}
        >
          <ListItemIcon>
            <FiMapPin />
          </ListItemIcon>
          <ListItemText>Import venues</ListItemText>
        </ListItem>
      </List>
      <List subheader={<ListSubheader>User</ListSubheader>}>
        <ListItem
          button
          component={Link}
          to="/invite"
          selected={isActiveRoute('/invite')}
        >
          <ListItemIcon>
            <FiMail />
          </ListItemIcon>
          <ListItemText>Invite user</ListItemText>
        </ListItem>
      </List>
      <ForReview />
      <List subheader={<ListSubheader>Other</ListSubheader>}>
        <ListItem
          button
          component={Link}
          to="/campaigns"
          selected={isActiveRoute('/campaigns')}
        >
          <ListItemIcon>
            <FiAward />
          </ListItemIcon>
          <ListItemText>Campaigns</ListItemText>
        </ListItem>
      </List>
    </>
  );
};

export default Navigation;
