import { createMuiTheme } from '@material-ui/core/styles';
import theme from '../default';
import {
  MuiInputLabel,
  MuiOutlinedInput,
  MuiFormHelperText,
  MuiSelect,
  MuiFormControlLabel,
  MuiCheckbox
} from './formControls';
import { MuiMenu, MuiMenuItem } from './menu';
import {
  MuiList,
  MuiListItem,
  MuiListItemText,
  MuiListItemIcon,
  MuiListSubheader
} from './list';
import { MuiDrawer } from './drawer';
import {
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions
} from './dialog';
import { MuiAppBar } from './appBar';

export default createMuiTheme({
  ui: theme,
  overrides: {
    MuiInputLabel,
    MuiOutlinedInput,
    MuiFormHelperText,
    MuiSelect,
    MuiFormControlLabel,
    MuiCheckbox,
    MuiMenu,
    MuiMenuItem,
    MuiList,
    MuiListItem,
    MuiListItemText,
    MuiListItemIcon,
    MuiListSubheader,
    MuiDrawer,
    MuiDialog,
    MuiDialogTitle,
    MuiDialogContent,
    MuiDialogActions,
    MuiAppBar
  }
});
