import React from 'react';
import MaterialDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Text from 'ui/Text';
import Flex from 'ui/Flex';
import Box from 'ui/Box';
import CloseIcon from '@material-ui/icons/Close';

const DialogTitle = props => {
  const { onClose, children } = props;

  return (
    <MaterialDialogTitle disableTypography>
      <Flex alignItems="center">
        <Text fontSize={3} color="text.primary" bold noWrap>
          {children}
        </Text>
        {onClose && (
          <Box ml="auto" color="grey.8" my={-3}>
            <IconButton aria-label="close" onClick={onClose} edge="end">
              <Flex fontSize={5}>
                <CloseIcon color="inherit" fontSize="inherit" />
              </Flex>
            </IconButton>
          </Box>
        )}
      </Flex>
    </MaterialDialogTitle>
  );
};

export default DialogTitle;
