import theme from '../default';

export const MuiMenu = {
  paper: {
    borderRadius: theme.radii.md,
    boxShadow: theme.shadows.lg
  }
};

export const MuiMenuItem = {
  root: {
    fontFamily: theme.fonts.sans,
    letterSpacing: theme.letterSpacings.normal,
    color: theme.colors.text.secondary,
    fontSize: theme.fontSizes[2],
    paddingTop: theme.space[3],
    paddingBottom: theme.space[3],
    paddingLeft: theme.space[4],
    paddingRight: theme.space[4],
    borderRadius: theme.radii.md,
    margin: `${theme.space[2]} ${theme.space[3]}`
  }
};
