import {
  getPromoCode as getPromoCodeApi,
  createPromoCode as createPromoCodeApi,
  updatePromoCode as updatePromoCodeApi,
  deletePromoCode as deletePromoCodeApi
} from '../api/promoCodes';
import {
  createAsyncAction,
  asyncInitialState,
  createAsyncReducerHandlers
} from './shared/async';
import { createReducer, combineReducers } from '@reduxjs/toolkit';

export const getPromoCode = createAsyncAction(
  'get-promo-code',
  getPromoCodeApi
);
export const createPromoCode = createAsyncAction(
  'create-promo-code',
  createPromoCodeApi
);
export const updatePromoCode = createAsyncAction(
  'update-promo-code',
  updatePromoCodeApi
);
export const deletePromoCode = createAsyncAction(
  'delete-promo-code',
  deletePromoCodeApi
);

const details = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(getPromoCode)
);

const deletingPromoCode = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(deletePromoCode)
);

export const promoCode = combineReducers({
  details,
  deletingPromoCode
});

export const editPromoCode = combineReducers({
  details
});
