import React from 'react';
import Box from 'ui/Box';
import Text from 'ui/Text';

const ErrorTitle = props => {
  return (
    <Box mt={2}>
      <Text fontSize={3} color="text.primary" bold>
        {props.children}
      </Text>
    </Box>
  );
};

export default ErrorTitle;
