import {
  createAsyncAction,
  asyncInitialState,
  createAsyncReducerHandlers
} from './shared/async';
import {
  getUser as getUserApi,
  getUserBatteriesLendings as getUserBatteriesLendingsApi,
  getUserPromoCodes as getUserPromoCodesApi,
  deleteUser as deleteUserApi,
  fixUserSwap as fixSwapApi
} from '../api/users';
import { createReducer, combineReducers } from '@reduxjs/toolkit';
import find from 'lodash/find';
import merge from 'lodash/merge';
import {
  endBatteryLending,
  refundBatteryLending,
  changeBatteryLendingAmount
} from './batteriesLendings';

export const getUser = createAsyncAction('get-user', getUserApi);
export const getUserBatteriesLendings = createAsyncAction(
  'get-user-batteries-lendings',
  getUserBatteriesLendingsApi
);
export const getUserPromoCodes = createAsyncAction(
  'get-user-promo-codes',
  getUserPromoCodesApi
);
export const deleteUser = createAsyncAction('delete-user', deleteUserApi);

export const fixSwap = createAsyncAction('fix-swap', fixSwapApi);

const details = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(getUser)
);

const deletingUser = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(deleteUser)
);

const fixingSwap = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(fixSwap)
);

const updateBatteryLending = updates => (state, action) => {
  const transactions = state.data || [];
  const foundTransaction = find(transactions, {
    batteryLendingId: action.meta.id
  });

  if (foundTransaction) {
    merge(foundTransaction, updates);
  }
};

const batteriesLendings = createReducer(asyncInitialState, {
  ...createAsyncReducerHandlers(getUserBatteriesLendings),

  [endBatteryLending.request]: updateBatteryLending({
    isEnding: true
  }),

  [endBatteryLending.success]: (state, action) => {
    if (!state.data) {
      return;
    }

    state.data = state.data.filter(
      rental => rental.batteryLendingId !== action.meta.id
    );
  },

  [endBatteryLending.error]: updateBatteryLending({
    isEnding: false
  }),

  [refundBatteryLending.request]: updateBatteryLending({
    isRefunding: true
  }),

  [refundBatteryLending.success]: (state, action) =>
    updateBatteryLending({
      isRefunding: false,
      isAmountRefunded: true,
      refundDetails: action.payload
    })(state, action),

  [refundBatteryLending.error]: updateBatteryLending({
    isRefunding: false
  }),

  [changeBatteryLendingAmount.request]: updateBatteryLending({
    isChangingAmount: true
  }),

  [changeBatteryLendingAmount.success]: (state, action) =>
    updateBatteryLending({
      cardDeduction: action.meta.amount,
      isChangingAmount: false
    })(state, action),

  [changeBatteryLendingAmount.error]: updateBatteryLending({
    isChangingAmount: false
  })
});

const promoCodes = createReducer(
  asyncInitialState,
  createAsyncReducerHandlers(getUserPromoCodes)
);

export const user = combineReducers({
  details,
  batteriesLendings,
  promoCodes,
  deletingUser,
  fixingSwap
});
