import React, { useState } from 'react';
import SearchInput from './SearchInput';
import { makeStyles } from '@material-ui/core/styles';
import CropFreeIcon from '@material-ui/icons/CropFree';
import IconButton from '@material-ui/core/IconButton';
import StationQrScannerDialog from '../StationQrScannerDialog/StationQrScannerDialog';

const useStyles = makeStyles(theme => ({
  qrScanIcon: {
    width: theme.spacing(7),
    top: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }
}));

const SearchInputWithStationScanner = props => {
  const classes = useStyles();
  const [isStationScannerOpen, setIsStationScannerOpen] = useState(false);

  const openStationScanner = () => {
    setIsStationScannerOpen(true);
  };

  const closeStationScanner = () => {
    setIsStationScannerOpen(false);
  };

  const onStationFoundByScanner = id => {
    closeStationScanner();
    props.onSelected({
      id,
      type: 'station'
    });
  };

  const actions = (
    <div className={classes.qrScanIcon}>
      <IconButton color="inherit" size="medium" onClick={openStationScanner}>
        <CropFreeIcon />
      </IconButton>
    </div>
  );

  return (
    <>
      <SearchInput {...props} actions={actions} />
      <StationQrScannerDialog
        open={isStationScannerOpen}
        onClose={closeStationScanner}
        onStationFound={onStationFoundByScanner}
      />
    </>
  );
};

export default SearchInputWithStationScanner;
