import React, { forwardRef } from 'react';
import Alert from 'ui/Alert';
import Box from 'ui/Box';
import { rem } from 'polished';

const Notification = forwardRef((props, ref) => {
  const { children, type, onClose } = props;

  return (
    <Box ref={ref} width={[1, rem(400)]}>
      <Alert variant={type} onClose={onClose} boxShadow="lg">
        {children}
      </Alert>
    </Box>
  );
});

export default Notification;
