import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import Overlay from 'ui/Overlay/Overlay';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import CheckCameraPermissions from './CheckCameraPermissions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { rem } from 'polished';
import { makeStyles } from '@material-ui/core/styles';
import { dataURLToBlob } from 'blob-util';

const useStyles = makeStyles({
  closeButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)'
  }
});

const CameraDialog = props => {
  const { onPictureTaken, open, onClose } = props;
  const [canTakePicture, setCanTakePicture] = useState(false);
  const cameraRef = useRef(null);
  const dialogRef = useRef(null);
  const classes = useStyles();

  const takePicture = () => {
    const image = cameraRef.current.getScreenshot();
    onPictureTaken({
      blob: dataURLToBlob(image),
      dataUrl: image
    });
  };

  const onUserMediaReady = () => {
    setCanTakePicture(true);
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen ref={dialogRef}>
      <Box position="relative" height="100%" width="100%">
        <Overlay>
          <CheckCameraPermissions>
            <>
              {open && (
                <Webcam
                  ref={cameraRef}
                  minScreenshotWidth={800}
                  minScreenshotHeight={800}
                  audio={false}
                  width="100%"
                  height="100%"
                  screenshotQuality={0.9}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{
                    facingMode: {
                      ideal: 'environment'
                    }
                  }}
                  onUserMedia={onUserMediaReady}
                />
              )}

              {canTakePicture && (
                <Box
                  align="center"
                  position="absolute"
                  bottom={rem(50)}
                  width="100%"
                  left="0"
                >
                  <Fab
                    onClick={takePicture}
                    size="large"
                    data-test-id="capture-photo"
                  >
                    <CameraAltOutlinedIcon />
                  </Fab>
                </Box>
              )}
            </>
          </CheckCameraPermissions>
        </Overlay>
      </Box>
      <Box px={1} py={1} position="absolute" top="0" right="0">
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Dialog>
  );
};

export default CameraDialog;
