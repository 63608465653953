import theme from '../default';
import { rem } from 'polished';

export const MuiList = {
  padding: {
    paddingTop: 0,
    paddingBottom: 0
  }
};

export const MuiListItem = {
  root: {
    paddingTop: theme.space[2],
    paddingBottom: theme.space[2],
    borderRadius: theme.radii.md,
    margin: `${theme.space[2]} ${theme.space[3]}`,
    width: `calc(100% - ${theme.space[3]} - ${theme.space[3]})`
  },

  gutters: {
    paddingLeft: theme.space[4],
    paddingRight: theme.space[4]
  },

  button: {
    '&$focusVisible': {
      backgroundColor: theme.colors.grey[1]
    },

    '&$selected, &$selected:hover': {
      backgroundColor: theme.colors.grey[1]
    },

    '&:hover': {
      backgroundColor: theme.colors.grey[0],

      '@media (hover: none)': {
        borderColor: 'transparent'
      }
    }
  }
};

export const MuiListItemText = {
  primary: {
    fontFamily: theme.fonts.sans,
    letterSpacing: theme.letterSpacings.normal,
    fontSize: theme.fontSizes[2],
    color: theme.colors.text.secondary
  },

  secondary: {
    fontFamily: theme.fonts.sans,
    letterSpacing: theme.letterSpacings.normal,
    fontSize: theme.fontSizes[1],
    color: theme.colors.text.tertiary
  }
};

export const MuiListItemIcon = {
  root: {
    color: theme.colors.grey[4],
    minWidth: rem(32),
    fontSize: theme.fontSizes[3]
  }
};

export const MuiListSubheader = {
  root: {
    fontFamily: theme.fonts.sans,
    fontSize: theme.fontSizes[0],
    color: theme.colors.text.tertiary,
    paddingTop: theme.space[5],
    lineHeight: theme.lineHeights.normal
  }
};
