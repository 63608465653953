import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import FullPage from 'ui/FullPage/FullPage';
import Box from 'ui/Box';

const PageLoader = () => {
  return (
    <FullPage>
      <Box color="grey.5">
        <CircularProgress color="inherit" />
      </Box>
    </FullPage>
  );
};

export default PageLoader;
