import React from 'react';
import useGeolocation from 'react-use/lib/useGeolocation';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { rem } from 'polished';
import Location from './location.svg';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

const isPermissionDeniedError = error => error.code === 1;

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: rem(300),
    flexDirection: 'column'
  },

  locationRequiredImage: {
    maxWidth: rem(240),
    width: '100%'
  }
}));

const RequestGeolocation = props => {
  const geolocation = useGeolocation();
  const classes = useStyles();
  const { t } = useTranslation();

  if (geolocation.loading) {
    return (
      <div className={classes.content}>
        <CircularProgress />
      </div>
    );
  }

  if (geolocation.error) {
    return (
      <div className={classes.content}>
        <img
          src={Location}
          alt="Location failed to load"
          className={classes.locationRequiredImage}
        />
        <Box pb={1}>
          <Typography variant="subtitle2" color="secondary">
            {isPermissionDeniedError(geolocation.error) &&
              t('requestGeolocation.errors.noAccess')}

            {!isPermissionDeniedError(geolocation.error) &&
              t('requestGeolocation.errors.notAvailable')}
          </Typography>
        </Box>
      </div>
    );
  }

  return props.children(geolocation);
};

export default RequestGeolocation;
