import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { rem } from 'polished';
import Logo from '../../../../shared/assets/logo.svg';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  page: {
    minHeight: '100vh',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4)
  },

  logo: {
    width: '100%',
    maxWidth: rem(140)
  }
}));

const Page = props => {
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <Box mb={2} textAlign="center">
        <img src={Logo} alt="ChargedUp Logo" className={classes.logo} />
      </Box>

      {props.children}
    </div>
  );
};

export default Page;
