import { get, post, put, del } from './shared';

export const getVenueById = async venueId => {
  const response = await get(`venues/${venueId}`);

  return response.data;
};

export const createVenue = async venue => {
  const response = await post('venues', {
    data: venue
  });

  return response.data;
};

export const updateVenue = async (venueId, updatedVenue) => {
  const response = await put(`venues/${venueId}`, {
    data: updatedVenue
  });

  return response.data;
};

export const attachStationToVenue = async (stationId, venueId, location) => {
  const response = await post(`venues/${venueId}/stations/${stationId}`, {
    data: { location }
  });

  return response.data;
};

export const detachStationFromVenue = async (stationId, venueId) => {
  await del(`venues/${venueId}/stations/${stationId}`);
};

export const getVenuesImportUrl = async () => {
  const response = await get('venues/import/url');

  return response.data;
};

export const importVenues = async importFileKey => {
  const response = await post('venues/import', {
    data: {
      key: importFileKey
    }
  });

  return response.data;
};

export const refreshVenueOpeningTimes = async venueId => {
  const response = await post(`venues/${venueId}/openings/refresh`);

  return response.data;
};

export const updateVenueOpeningTimes = async (venueId, openings) => {
  const response = await put(`venues/${venueId}/openings`, {
    data: openings
  });

  return response.data;
};

export const getVenueActivity = async (venueId, fromDate, toDate) => {
  const response = await get(`venues/${venueId}/activity`, {
    params: { fromDate, toDate }
  });

  return response.data;
};

export const getVenueRevenue = async venueId => {
  const response = await get(`venues/${venueId}/revenue`);

  return response.data;
};

export const getVenueStations = async (venueId, sizePerPage = 1000, after) => {
  const response = await get(`venues/${venueId}/stations`, {
    params: { sizePerPage, after }
  });

  return response.data;
};

export const deleteVenue = async venueId => {
  await del(`venues/${venueId}`);
};
