import styled from 'styled-components/macro';
import Text from 'ui/Text';

const CardTitle = styled(Text)``;

CardTitle.defaultProps = {
  fontSize: 2,
  bold: true,
  color: 'text.secondary'
};

export default CardTitle;
