import { get } from './shared';

export const searchAll = async query => {
  const response = await get('search', {
    params: { query }
  });

  return response.data;
};

export const searchStations = async (query, filter = {}) => {
  const response = await get('stations/search', {
    params: { query, ...filter }
  });

  return response.data;
};

export const searchVenues = async query => {
  const response = await get('venues/search', {
    params: { query }
  });

  return response.data;
};
