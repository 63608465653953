import React from 'react';
import LogoImage from './logo.svg';
import Box from 'ui/Box';
import styled from 'styled-components/macro';
import { rem } from 'polished';

const Image = styled('img')`
  height: 100%;
  width: 100%;
  display: block;
`;

const Logo = props => {
  return (
    <Box {...props}>
      <Image src={LogoImage} />
    </Box>
  );
};

Logo.defaultProps = {
  width: '100%',
  maxWidth: rem(140)
};

export default Logo;
