import React, { memo } from 'react';
import MuiButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import theme from 'ui/theme/default';
import merge from 'lodash/merge';
import { rem } from 'polished';
import CircularProgress from '@material-ui/core/CircularProgress';

const primaryColors = {
  danger: {
    backgroundColor: theme.colors.red[5],

    '&:hover': {
      backgroundColor: theme.colors.red[6]
    }
  },

  success: {
    backgroundColor: theme.colors.green[5],

    '&:hover': {
      backgroundColor: theme.colors.green[6]
    }
  },

  default: {
    backgroundColor: theme.colors.grey[7],

    '&:hover': {
      backgroundColor: theme.colors.grey[8]
    }
  }
};

const secondaryColors = {
  danger: {
    color: theme.colors.red[5]
  },

  success: {
    color: theme.colors.green[5]
  },

  default: {
    color: theme.colors.text.secondary
  }
};

const tertiaryColors = {
  default: {
    color: theme.colors.text.secondary,

    '&:hover': {
      backgroundColor: theme.colors.grey[1]
    }
  },

  danger: {
    color: theme.colors.red[5],

    '&:hover': {
      backgroundColor: theme.colors.red[0]
    }
  },

  success: {
    color: theme.colors.green[5],

    '&:hover': {
      backgroundColor: theme.colors.green[1]
    }
  }
};

const outlinedColors = {
  default: {
    color: theme.colors.text.secondary,
    borderColor: theme.colors.text.secondary,

    '&:hover': {
      backgroundColor: theme.colors.grey[1]
    }
  },

  danger: {
    color: theme.colors.red[5],
    borderColor: theme.colors.red[5],

    '&:hover': {
      backgroundColor: theme.colors.red[0]
    }
  },

  success: {
    color: theme.colors.green[5],
    borderColor: theme.colors.green[5],

    '&:hover': {
      backgroundColor: theme.colors.green[1]
    }
  }
};

const filledColors = {
  default: {
    color: theme.colors.text.secondary
  },

  danger: {
    color: theme.colors.red[5]
  },

  success: {
    color: theme.colors.green[5]
  }
};

const variants = props =>
  ({
    primary: merge(
      {
        color: 'white',
        boxShadow: theme.shadows.sm,

        '&:hover': {
          boxShadow: theme.shadows.md
        },

        '&.Mui-disabled': {
          color: theme.colors.grey[2],
          backgroundColor: theme.colors.grey[1],
          boxShadow: theme.shadows.none
        }
      },
      primaryColors[props.color]
    ),

    secondary: merge(
      {
        backgroundColor: 'white',
        boxShadow:
          '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 1px rgba(0, 0, 0, 0.06)',

        '&:hover': {
          backgroundColor: 'white',
          boxShadow: theme.shadows.md
        },

        '&.Mui-disabled': {
          color: theme.colors.grey[1],
          boxShadow: theme.shadows.none
        }
      },
      secondaryColors[props.color]
    ),

    tertiary: merge(
      {
        '&.Mui-disabled': {
          color: theme.colors.grey[2]
        }
      },
      tertiaryColors[props.color]
    ),

    outlined: merge(
      {
        borderWidth: theme.borderWidths[2],
        borderStyle: 'solid',
        padding: `${rem(6)} ${rem(18)}`,

        '&.Mui-disabled': {
          color: theme.colors.grey[2],
          borderColor: theme.colors.grey[2]
        },

        '&.MuiButton-sizeSmall': {
          padding: `${rem(2)} ${rem(10)}`
        },

        '&.MuiButton-sizeLarge': {
          padding: `${rem(10)} ${rem(22)}`
        }
      },
      outlinedColors[props.color]
    ),

    filled: merge(
      {
        backgroundColor: theme.colors.grey[1],

        '&:hover': {
          backgroundColor: theme.colors.grey[2]
        },

        '&.Mui-disabled': {
          color: theme.colors.grey[2],
          backgroundColor: theme.colors.grey[1]
        }
      },
      filledColors[props.color]
    )
  }[props.variant]);

const useStyles = makeStyles({
  root: props => ({
    fontFamily: theme.fonts.sans,
    lineHeight: theme.lineHeights.relaxed,
    letterSpacing: theme.letterSpacings.normal,
    textTransform: 'initial',
    fontSize: theme.fontSizes[2],
    fontWeight: 600,
    padding: `${theme.space[2]} ${theme.space[5]}`,
    borderRadius: theme.radii.md,
    position: 'relative',
    overflow: 'hidden',
    ...variants(props)
  }),

  sizeLarge: {
    '&&': {
      fontSize: theme.fontSizes[3],
      padding: `${theme.space[3]} ${theme.space[6]}`
    }
  },

  sizeSmall: {
    '&&': {
      fontSize: theme.fontSizes[1],
      padding: `${theme.space[1]} ${theme.space[3]}`
    }
  }
});

const Button = memo(props => {
  const {
    color = 'default',
    variant = 'primary',
    children,
    startIcon,
    disabled,
    loading = false,
    ...buttonProps
  } = props;
  const classes = useStyles({ color, variant });

  return (
    <MuiButton
      classes={classes}
      {...buttonProps}
      startIcon={
        loading ? <CircularProgress size="1em" color="inherit" /> : startIcon
      }
      disabled={loading || disabled}
    >
      {children}
    </MuiButton>
  );
});

export default Button;
