import { useUserRoles } from '../../shared/hooks/useUserRoles';

const AgentOnly = props => {
  const { isAgent } = useUserRoles();

  if (!isAgent) {
    return null;
  }

  return props.children;
};

export default AgentOnly;
